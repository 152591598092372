import React from "react";
import { Status } from "./pedidos/StatusPedido";
import { Endpoints } from "./Endpoints";
import { ModalLojas, ModalClientes } from "./ModalTypes";
import moment from "moment";
import "sweetalert2/dist/sweetalert2.min.css";
import swal from "sweetalert2";
import ReactPaginate from 'react-paginate';

export class Tabela extends React.Component {
  constructor() {
    super();
    this.state = {
      pagina: 0
    };
  }

  componentWillMount() {
    this.setState({ conteudo: this.props.conteudo });
  }
  //Monta o conteúdo da coluna Opções, que pode variar pelo tipo de listagem
  montaOpcoes = dados => {
    let opcoes = null;
    switch (this.props.dados.tipo) {
      case "pedidos":
        opcoes = (
          <div>
            <button
              type="button"
              className="btn bg-amber btn-circle waves-effect waves-circle waves-float"
              data-toggle="tooltip"
              title="Detalhes do Pedido"
              onClick={() => {
                this.props.metodos.callModal(dados);
              }}
              style={botaoLista}
            >
              <i className="material-icons">assignment</i>
            </button>

            {/* <button
                            type="button"
                            className="btn bg-red btn-circle waves-effect waves-circle waves-float"
                            data-toggle="tooltip"
                            title="Deletar Pedido"
                            onClick={
                                () => {
                                    this.props.metodos.delPedido(dados);
                                }
                            }
                            style={botaoLista}
                        >
                            <i className="material-icons">delete</i>
                        </button> */}
          </div>
        );
        break;

      case "lojas":
        opcoes = (
          <div>
            <button
              type="button"
              className="btn bg-orange btn-circle waves-effect waves-circle waves-float"
              data-toggle="tooltip"
              title="Editar Loja"
              style={botaoLista}
              onClick={() => {
                let params = {
                  titulo: "Editar",
                  tipo: "edit",
                  fields: ModalLojas.conteudoEstrutura.fields,
                  data: dados,
                  endpoint: Endpoints.updateStore
                };
                this.props.metodos.callModal(params);
              }}
            >
              <i className="material-icons">mode_edit</i>
            </button>

            <button
              type="button"
              className="btn bg-deep-orange btn-circle waves-effect waves-circle waves-float"
              data-toggle="tooltip"
              title="Ver no Mapa"
              style={botaoLista}
              onClick={() => {
                this.props.metodos.verMapa({
                  lat: dados.latitude,
                  lng: dados.longitude,
                  address: dados.address
                });
              }}
            >
              <i className="material-icons">map</i>
            </button>
          </div>
        );
        break;

      case "clientes":
        opcoes = (
          <div>
            <button
              type="button"
              className="btn bg-orange btn-circle waves-effect waves-circle waves-float"
              data-toggle="tooltip"
              title="Editar"
              style={botaoLista}
              onClick={() => {
                let params = {
                  titulo: "Editar",
                  tipo: "edit",
                  fields: ModalClientes.conteudoEstrutura.fields,
                  data: dados,
                  endpoint: Endpoints.updateClient
                };
                this.props.metodos.callModal(params);
              }}
            >
              <i className="material-icons">edit</i>
            </button>

            <button
              type="button"
              className="btn bg-deep-orange btn-circle waves-effect waves-circle waves-float"
              data-toggle="tooltip"
              title="Ver no Mapa"
              style={botaoLista}
              onClick={() => {
                if (dados.delivery_addresses.length > 0) {
                  this.props.metodos.verMapa({
                    lat: dados.delivery_addresses[0].latitude,
                    lng: dados.delivery_addresses[0].longitude,
                    address: dados.delivery_addresses[0].address
                  });
                } else {
                  swal(
                    "",
                    "O cliente, não possui nenhum endereço cadastrado.",
                    "info"
                  );
                }
              }}
            >
              <i className="material-icons">map</i>
            </button>
          </div>
        );
        break;

      default:
        opcoes = <div />;
        break;
    }
    return opcoes;
  };

  //Monta a paginação
  montaPaginacao = () => {
    let itensPorPagina = this.props.dados.itensPorPagina;
    let qtdItens = this.props.dados.qtdItens;
    let pag = this.props.dados.pagina;
    let htmlPaginacao = [];
    //Caso seja menor, não terá paginação
    if (qtdItens <= itensPorPagina) {
      htmlPaginacao.push(
        <li
          key={0}
          className="active"
          onClick={() => this.props.metodos.alteraPagina(0)}
        >
          <a
            className="waves-effect"
            href="/"
            onClick={e => {
              e.preventDefault();
            }}
          >
            1
          </a>
        </li>
      );
    } else {
      //Caso seja maior, deverá mostrar a paginação
      let qtdDePaginas = Math.ceil(qtdItens / itensPorPagina);
      let i = 0;
      while (i < qtdDePaginas) {
        let pagin = i;
        htmlPaginacao.push(
          <li
            key={i}
            className={i === pag ? "active" : ""}
            onClick={() => this.props.metodos.alteraPagina(pagin)}
          >
            <a
              className="waves-effect"
              href="/"
              onClick={e => {
                e.preventDefault();
              }}
            >
              {i}
            </a>
          </li>
        );
        i++;
      }
    }

    return htmlPaginacao;
  };

  //Formata as datas para o padrão brasileiro
  imprimeData = data => {
    let completeData = moment(data);
    return completeData.format("DD/MM/YYYY HH:MM");
  };


  render() {
    let itensPorPagina = this.props.dados.itensPorPagina;
    let qtdItens = this.props.dados.qtdItens;

    return (
      <div className="row clearfix">
        <div className="col-lg-4 col-md-4">
          <div className="form-group">
            <label>Itens por página:</label>
            <select
              style={{
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12)',
                borderRadius: 2,
                border: 'none',
                fontSize: 13,
                width: 150,
                height: 38,
                marginLeft: 20
              }}
              ref="itensPorPagina"
              onChange={e => {
                this.props.metodos.eventItensPorPagina(e);
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 table-responsive">
          <table className="table">
            <thead>
              <tr>
                {this.props.dados.cabecalho.map((cabecalho, indice) => {
                  return (
                    <th key={indice} className={cabecalho.classe}>
                      {cabecalho.nome}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {this.props.conteudo !== null ? (
                this.props.conteudo.map((listagem, indice) => {
                  return (
                    <tr key={indice}>
                      {this.props.dados.cabecalho.map((cabecalho, indice) => {
                        // console.log(Status.Pedidos[
                        //   listagem[cabecalho.indexacao]
                        // ], listagem[cabecalho.indexacao])
                        return (
                          <td key={indice} className={cabecalho.classeCol} >
                            {cabecalho.tipo === "options" ? (
                              this.montaOpcoes(listagem)
                            ) : cabecalho.indice !== "" ? (
                              listagem[cabecalho.indice] !== null ? (
                                listagem[cabecalho.indice][cabecalho.indexacao]
                              ) : (
                                  ""
                                )
                            ) : Status.Pedidos[
                              listagem[cabecalho.indexacao]] && this.props.dados.tipo === "pedidos" &&
                              cabecalho.indexacao === "state" ? (
                                    <h4>
                                      <span
                                        className={
                                          listagem[cabecalho.indexacao] !== undefined
                                            ? Status.Pedidos[
                                              listagem[cabecalho.indexacao]
                                            ].classeLabel
                                            : "label"
                                        }
                                      >
                                        {listagem[cabecalho.indexacao] !== undefined
                                          ? Status.Pedidos[
                                            listagem[cabecalho.indexacao]
                                          ].texto
                                          : ""}
                                      </span>
                                    </h4>
                                  ) : cabecalho.tipo === "data" ? (
                                    this.imprimeData(listagem[cabecalho.indexacao])
                                  ) : (
                                      listagem[cabecalho.indexacao]
                                    )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                  <tr>
                    <td colSpan={this.props.dados.cabecalho.length}>
                      <div className="preloader">
                        <div className="spinner-layer pl-red">
                          <div className="circle-clipper right">
                            <div className="circle" />
                          </div>
                          <div className="circle-clipper left">
                            <div className="circle" />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
        <div className="col-lg-5 col-md-3 col-lg-offset-5 col-md-offset-5">
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(qtdItens / itensPorPagina)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.props.metodos.alteraPagina}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </div>
      </div>
    );
  }
}

//Monta a tabela responsável por listar as farmácias do modal de detalhes do pedido
export class TabelaFarmacia extends React.Component {
  render() {
    let icones = {
      novo: "fa fa-spinner fa-pulse fa-fw",
      expirado: "fa fa-clock-o",
      rejeitado: "fa fa-times",
      aceito: "fa fa-check-circle-o",
      ganho: "fa fa-check-circle",
      perdido: "fa fa-hourglass-end"
    };

    return (
      <ul className="list-group">
        {this.props.listagem.length > 0 ? (
          this.props.listagem.map((dadosFarma, indice) => {
            let idCollapse = "collapse" + indice;
            let dataTarget = "#collapse" + indice;
            return (
              dadosFarma.state !== "expirado" && (
                <div key={indice}>
                  <li
                    className="list-group-item"
                    data-toggle="collapse"
                    data-target={dataTarget}
                    style={{ cursor: "pointer" }}
                  >
                    {
                      <b>
                        <i className={icones[dadosFarma.state]} />
                        &nbsp;{dadosFarma.store.name}
                      </b>
                    }
                    {"aceitou" in dadosFarma ? (
                      <b>
                        <i
                          className={
                            Status.StatePedidos["aguardando ofertas accept"]
                              .icone
                          }
                        />
                        &nbsp;{dadosFarma.store.name}
                      </b>
                    ) : (
                        <b>
                          <i
                            className={
                              Status.StatePedidos[dadosFarma.state].icone
                            }
                          />
                        &nbsp;{dadosFarma.store.name}
                        </b>
                      )}
                  </li>
                  <div className="collapse" id={idCollapse}>
                    <div className="well">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th colSpan="2">
                              <i className="material-icons">
                                store_mall_directory
                              </i>{" "}
                              Detalhes da Loja:
                            </th>
                          </tr>
                          <tr>
                            <th>#</th>
                            <th>NOME</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{dadosFarma.store.id}</td>
                            <td>{dadosFarma.store.name}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )
            );
          })
        ) : (
            <li>Nenhuma farmácia encontrada.</li>
          )}
      </ul>
    );
  }
}

/* STYLE DOS BOTÕES DE OPÇÕES DE UM PEDIDO */
const botaoLista = {
  margin: 5
};

import React, { Component } from "react";

import { Wrapper } from "./Home.styles";
import HomeMaps from "./HomeMaps";

import Modal from "../../components/Modal";
// import ModalContent from "../../pedidos/detalhes/ModalContent";
import { listarPedidos, listarLojas, ordersWSConnection } from "./HomeApis";
import { MARKER_TYPE } from "../../enum/MarkerType";

export class Home extends Component {
  state = {
    currentPage: 1,
    isModalOpen: false,
    idPedido: null,
    orders: [],
    stores: [],
    ordersWS: null,
    notification: null
  };

  componentDidMount() {
    listarPedidos(this.changeOrders, this.state.currentPage);
    listarLojas(this.changeStores);
    ordersWSConnection(this.onReceiveOrderMessage);
  }

  onReceiveOrderMessage = notification => {
    this.changeOrders([notification.order]);
    this.setState({ notification });
  };

  changeOrders = orders =>
    this.setState(prevState => ({
      orders: [...prevState.orders, ...orders]
    }));
  changeStores = stores => this.setState({ stores });

  loadOlderOrders = () => {
    this.setState(
      prevState => ({ currentPage: prevState.currentPage + 1 }),
      () => listarPedidos(this.changeOrders, this.state.currentPage)
    );
  };

  onMarkerClick = (idPedido, type) => {
    if (type === MARKER_TYPE.ORDER)
      this.setState({ isModalOpen: true, idPedido });
  };

  closeModal = () => this.setState({ isModalOpen: false });

  render() {
    const { isModalOpen, idPedido, orders, stores, notification } = this.state;
    const markers = { orders, stores };
//console.log(isModalOpen, idPedido )
    return (
      <Wrapper className="card">
        <HomeMaps
          markers={markers}
          onMarkerClick={this.onMarkerClick}
          loadOlderOrders={this.loadOlderOrders}
          notification={notification}
        />
{/* 
        <Modal isOpen={isModalOpen}>
          <ModalContent idPedido={idPedido} closeModal={this.closeModal} />
        </Modal> */}
      </Wrapper>
    );
  }
}


import React from 'react';
import './Marker.css';

const Marker = (props: any) => {
    const { color, name, id, onClick } = props;
    const test = () => onClick()
    return (
      <div onClick={() => test()}>
        <div
          className="pin bounce"
          style={{ backgroundColor: color, cursor: 'pointer' }}
          title={name}
        />
        <div className="pulse" />
      </div>
    );
  };

  export default Marker;
import React, { Component } from "react";
import { compose, withProps } from "recompose";

import GoogleMapReact from 'google-map-react';

import storeIcon from "../../../google-maps/icons/store-32.png";

import { GOOGLE_URL } from "../../../Endpoints";
import { Default } from "../../../google-maps/themes";
import OlderOrdersButton from "./OlderOrdersButton";
import MultiLocation from "./MultiLocation";
import Marker from "./Marker";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

class HomeMaps extends Component {
  state = {
    isCentered: false,
    center: { lat: 0, lng: 0 },
    zoom: 16,
    multiLocation: []
  };

  componentDidUpdate(prevProps) {
    // console.log(this.props.markers.stores.length !==
    //   prevProps.markers.stores.length)
    if (
      this.props.markers.stores.length !==
      prevProps.markers.stores.length
    ) {

      this.setState({
        center: {
          lat: this.props.markers.stores[1].latitude,
          lng: this.props.markers.stores[1].longitude
        },
        zoom: 13
      });
    }
  }

  fitExampleBounds = () => {
    if (!this.state.isCentered) {
      this.setState({ isCentered: true });
      const { markers } = this.props;
      const { LatLng, LatLngBounds } = window.google.maps;

      const markersBounds = [
        ...markers.stores.map(
          mark => new LatLng(mark.latitude, mark.longitude)
        ),
        ...markers.orders.map(mark => new LatLng(mark.latitude, mark.longitude))
      ];

      const bounds = new LatLngBounds();
      markersBounds.forEach(bound => bounds.extend(bound));

      this.refs.map.fitBounds(bounds, 60);
      this.fitExampleBounds = () => { };
    }
  };

  onMarkerClustererClick = markerClusterer => {
    this.setState({ multiLocation: markerClusterer.getMarkers() });
  };

  _renderMark = (mark, icon, key) => (
    <Marker
      key={`${key}-${mark.id}`}
      text={JSON.stringify(mark)}
      lat={mark.latitude} lng={mark.longitude}
      icon={icon}
      onClick={() => this.props.onMarkerClick(mark.id, mark.type)}
    />
  );

  render() {
    const { center, zoom, multiLocation } = this.state;
    const { markers, loadOlderOrders } = this.props;
    const AnyReactComponent = ({ text }) => <div>{text}</div>;
    //console.log(this.props, this.state)
    if (markers.stores.length === 0) return null

    return (
      <div style={{ height: '100vh', width: '100%' }}>

        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDnD3p8cvMPK6XOZKMzqiqWDi7wss1F6Ec' }}
          defaultCenter={center}
          defaultZoom={zoom}
        >


          {markers.orders.map(mark =>
            this._renderMark(mark, storeIcon, "store")
          )}



          <OlderOrdersButton onClick={loadOlderOrders} />
        </GoogleMapReact>
      </div>
    );
  }
}

export default HomeMaps


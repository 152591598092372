import React from "react";

const StoreCard = (props) => {
  const { name, address, city, state, country, phone, delivery_fee, deliveryAddress } = props;
  const client_address = `${deliveryAddress.street},+${deliveryAddress.number}+-+${deliveryAddress.neighborhood},+${deliveryAddress.city}+-+${deliveryAddress.state},+${deliveryAddress.cep}`;
  const theme = `https://www.google.com/maps/search/farmacia/@${deliveryAddress.latitude},${deliveryAddress.longitude},15z/data=!4m8!2m7!3m6!1sfarmacia!2s${client_address}!3s0x9bd0bdcb4d385f:0x3246793563845369!4m2!1d${deliveryAddress.longitude}!2d${deliveryAddress.latitude}`;
  const gmaps_link = theme;
  return (
    <div className="col-sm-12 col-md-12 col-lg-12">
      <div className="card">
        <div className="body bg-grey">
          <div className="table-resonsive">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="2">
                    <span>
                      <i className="material-icons">info_outline</i> INFORMAÇÕES
                      DA LOJA:
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {name && (
                  <tr>
                    <th>Nome:</th>
                    <td>{name}</td>
                  </tr>
                )}
                {address && (
                  <tr>
                    <th>Endereço:</th>
                    <td>
                      {address}
                      {", " + city}
                      {" - " + state}
                      {", " + country}
                    </td>
                  </tr>
                )}
                {phone && (
                  <tr>
                    <th>Telefone:</th>
                    <td>{phone}</td>
                  </tr>
                )}
                <tr>
                  <th>Lojas próximas:</th>
                  <td><button onClick={()=>window.open(gmaps_link, "_blank")} style={{color:'black'}}>Abrir Google</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreCard;

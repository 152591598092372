import React from "react";
import moment from "moment";
import "moment/locale/pt-br";
import "font-awesome/css/font-awesome.min.css";

import { MARKER_TYPE } from "../../../../enum/MarkerType";

const ListItem = ({
  id,
  type,
  name,
  state,
  description,
  productsCount,
  createdTime,
  hasStore,
  onClick
}) => {
  moment.locale("pt-br");
  const text = name || state;
  const Icon = () =>
    type === MARKER_TYPE.STORE ? (
      <i className="fa fa-home fa-2x" style={{ margin: "0 4px", width: 32 }} />
    ) : (
      <i
        className="fa fa-map-marker fa-2x"
        style={{ margin: "0 4px", width: 32, color: "red" }}
      />
    );

  return (
    <button
      type="button"
      className="btn btn-link waves-effect"
      onClick={() => onClick(id, type)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        margin: "6px 0",
        padding: 0,
        backgroundColor: description === "em andamento" ? "#90EE90" : "white"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <Icon />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
          }}
        >
          <p
            style={{
              margin: 0
            }}
          >
            {id} - {text}
          </p>
          {type === MARKER_TYPE.ORDER && (
            <p
              style={{
                margin: 0,
                padding: 0,
                color: "#777",
                fontSize: 12,
                fontWeight: 400,
                fontStyle: "italic"
              }}
            >
              {moment(createdTime).fromNow()}
            </p>
          )}
        </div>
      </div>
      {productsCount && (
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          {hasStore && <i className="fa fa-home fa-lg" />}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 22,
              height: 22,
              backgroundColor: "red",
              borderRadius: "50%",
              margin: "0 8px"
            }}
          >
            <p style={{ margin: 0, color: "white", fontSize: 12 }}>
              {productsCount}
            </p>
          </div>
        </div>
      )}
    </button>
  );
};

export default ListItem;

import React, { Component } from 'react';
import {APIRequest, APIFetch} from './Endpoints.js';

export default class MultistageForm extends Component {
    constructor(props) {
        super(props);
    
        this.nextStage = this.nextStage.bind(this);
        this.previousStage = this.previousStage.bind(this);
        this.submit = this.submit.bind(this);
    
        // Variável de estado que determina a visibilidade de cada estágio.
        var visible = new Array(this.props.children.length)
        for (var i = 0; i < visible.length; ++i) { visible[i] = false;}
        visible[0] = true;
        this.state = {
          visible: visible
        }
    }

    // Implement next and previous if you want to have access to the form
    // transition events.
    static defaultProps = {
        nextDisplay: "Próximo",
        previousDisplay: "Anterior",
        submitDisplay: "Cadastrar"
    }

    nextStage(index, event) {
        var visible = this.state.visible;
        visible[index] = false;
        visible[index+1] = true;
        this.setState({
          visible: visible
        })

        if (this.props.next) {
            this.props.next(index);
        }
    }

    previousStage(index, event) {
        var visible = this.state.visible;
        visible[index] = false;
        visible[index-1] = true;
        this.setState({
          visible: visible
        })

        if (this.props.previous) {
            this.props.previous(index);
        }
    }

    submit(event) {
        // TODO: Default behaviour, should close form?
        if (this.props.submit) {
            this.props.submit();
        }
    }

    render() {
        return (
            <div>
                <form id="multistage-form">
                    <div id="progressbar" className="row clearfix">
                        {React.Children.map(this.props.children, function(stage, index) {
                            var currentIndex = this.state.visible.indexOf(true);
                            if (index <= currentIndex) {
                                //return <li className="active" key={index}>{stage.props.name}</li>
                                return <div className="col-sm-4">
                                            <div className="info-box bg-green">
                                                <div className="content">
                                                    <div className="number">{index+1} - {stage.props.name}</div>
                                                </div>
                                            </div>
                                        </div>;
                            } else {
                                //return <li key={index}>{stage.props.name}</li>
                                return <div className="col-sm-4">
                                            <div className="info-box bg-grey">
                                                <div className="content">
                                                    <div className="number">{index+1} - {stage.props.name}</div>
                                                </div>
                                            </div>
                                        </div>;
                            }
                        }, this)}
                    </div>
                    <div className="col-sm-12">
                    {React.Children.map(this.props.children, function(stage, index) {
    
                        // Navegação do formulário multiestágio.
                        var navigation;
                        var next = 
                                    <input type="button" 
                                        onClick={this.nextStage.bind(this, index)} 
                                        name="next" 
                                        className="btn btn-lg bg-green waves-effect pull-right" 
                                        value={this.props.nextDisplay}
                                        disabled={this.props.nextDisabled}/>;

                        var previous = 
                                    <input type="button" 
                                        onClick={this.previousStage.bind(this, index)} 
                                        name="previous" 
                                        className="btn btn-lg bg-grey waves-effect pull-left" 
                                        value={this.props.previousDisplay}/>;

                        var submit =      
                                    <input type="button" 
                                        onClick={this.submit.bind(this)} 
                                        name="submit" 
                                        className="btn btn-lg bg-green waves-effect pull-right" 
                                        value={this.props.submitDisplay}/>;

                        if (index === 0) {
                            navigation = (
                                <div>
                                {next}
                                </div>
                            )
                        } else if (index === React.Children.count(this.props.children) - 1) {
                            navigation = (
                                <div>
                                {previous}
                                {submit}
                                </div>
                            )
                        } else {
                            navigation = (
                                <div>
                                {previous}
                                {next}
                                </div>
                            )
                        }
    
                // Renderização do estágio.
                if (this.state.visible[index]) {
                  return (
                    <fieldset key={index}>
                      <h4 className="fieldset-title">{stage.props.name}</h4>
                      {stage}
                      {navigation}
                    </fieldset>
                  )
                } else { 
                  return null
                }
              }, this)}
              </div>
            </form>
          </div>
        )
    }
}

export class Form extends Component {
    constructor(props) {
      super(props);
  
      // Navigation calls.
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
      this.submit = this.submit.bind(this);

      // Data update calls.
      this.updateField = this.updateField.bind(this);
    }

    next() {}

    previous() {}

    submit() {
      let endpoint = this.props.action;

      if (endpoint) {
        let request = APIRequest(endpoint, this.state, sessionStorage.apitoken);
        var form = this;
        APIFetch(request, function(result) {
          form.props.close();
        });
      } else {
        this.props.close()
      }

    }

    /**
     * @param  {} field
     */
    updateField(field) {
      this.setState({
        [field.name]: field.value
      })
    }
}

export class FieldForm extends Component {
    constructor(props) {
      super(props);
  
      this.handleChange = this.handleChange.bind(this);
    }
  
    handleChange(index, event) {
      var value = event.target.value;
      var name = event.target.id;

      try {
        this.props.onChange({name: name, value: value});
      } catch(err) {
        console.error(`[${this.constructor.name}] ${err.message}`);
      }
    }
  
    render() {
      var data = new Array(this.props.fields.length)
      for (var i = 0; i < this.props.fields.length; ++i) {
  
        var key = this.props.fields[i].key;
        if (this.props.data && this.props.data[key]) {
          data[i] = this.props.data[key];
        } else { 
          data[i] = "";
        }
      }
      return (
            this.props.fields.map(function(field, index) {
    
                return <div key={index} className="form-group">
                            <div className="form-line">   
                                <input  
                                    id={field.key}
                                    type={field.type} 
                                    value={data[index]} 
                                    placeholder={field.name}
                                    onChange={this.handleChange.bind(this, index)}
                                    className="form-control" />
                            </div>
                        </div>
            }, this)
        )
    }
}

export class Field extends Component {
  render() {
    return (
      <FieldForm fields={this.props.fields} data={this.props.data} onChange={this.props.onChange}/>
    )
  }
}

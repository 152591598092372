import React from "react";

const PaymentCard = (props) => {
  const { payments, delivery_fee, discounted_price, rules } = props;
  return (
    <div className="col-sm-12 col-md-12 col-lg-12">
      <div className="card">
        <div className="body bg-grey">
          <div className="table-resonsive">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="2">
                    <span>
                      <i className="material-icons">info_outline</i> INFORMAÇÕES
                      DE PAGAMENTO:
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {payments ? (
                  <tr>
                    <th>Tipo:</th>
                    <td>{`${payments.length > 0 ? 'Cartão final '+payments[0].card : 'Na entrega'}`}</td>
                  </tr>
                ) :null}
                {delivery_fee ? (
                  <tr>
                    <th>Frete:</th>
                    <td>R$ {delivery_fee}</td>
                  </tr>
                ) :null}
                {discounted_price ? (
                  <tr>
                    <th>Cupom:</th>
                    <td>R$ {discounted_price}</td>
                  </tr>
                ) :null}
                {rules ? (
                  <tr>
                    <th>Observações:</th>
                    <td>{rules.client_observation}</td>
                  </tr>
                ) :null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;

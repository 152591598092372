/*
    CLASSE RESPONSÁVEL POR TRADUZIR OS ERROS RETORNADOS PELO FETCH
*/
export class Error {

    static Login = {
        /*"non_field_errors": [
            : { "error": "Unable to log in with provided credentials.", "error_translated": "Não foi possível logar com as credenciais informadas."}
        ]*/
        "Unable to log in with provided credentials.": "Não foi possível logar com as credenciais informadas.",
        
    }

}
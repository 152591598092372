import React, {Component} from 'react';
import {APIRequest, APIFetch} from './Endpoints';
import Map from './google-maps/Map';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { Aubergine } from './google-maps/themes';
import './ModalTypes';
import 'sweetalert2';
import store from './google-maps/icons/store-32.png';

const MyMapComponent = withScriptjs(withGoogleMap((props) => {
        let ObjCenter = { lat: props.data.lat, lng: props.data.lng }
        return (
        <GoogleMap
            defaultZoom={16}
            defaultCenter={ObjCenter}
            defaultOptions={{ styles: Aubergine }}
        >
            {props.data.isMarkerShown && <Marker position={{ lat: props.data.lat, lng: props.data.lng }} defaultIcon={store} />}
        </GoogleMap>);
    }
))

export class Modal extends Component {
    componentDidMount() {
        if (this.props.params.tipo === "map") {
            this.state = {
                id: this.props.params.data.id,
                mapa: <Map />
            }
        } else {
            this.state = {
                id: this.props.params.data.id
            }
        }
    }

    updateField = (field) => {
        this.setState({
            [field.name]: field.value
        })
    }

    //Chama o envio do formulário
    submit = () => {
        if(Object.keys(this.state).length <= 1){
            window.swal(
                'Oops...',
                'Nenhum dado foi alterado.',
                'warning'
            )
        }else{
            let paramsSubmit = this.state;

            let request = APIRequest(this.props.params.endpoint, paramsSubmit, sessionStorage.apitoken);
            APIFetch(request, (resUpdate) => {
                if(Object.keys(this.state).length > 0){
                    window.swal(
                        'Sucesso!',
                        'Salvo com sucesso!',
                        'success'
                    )
                }else{
                    window.swal(
                        'Oops...',
                        'Tivemos um problema ao salvar, tente novamente mais tarde!',
                        'error'
                    )
                }
            })
        }
        
    }

    render() {
        return(
        <div className="modal fade" id="modalAdmin" role="dialog">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row clearfix">
                           <MontaConteudo params={this.props.params} fields={this.props.params.fields} evChange={this.updateField} />
                        </div>
                    </div>
                    {
                        (this.props.params.tipo === "info" || this.props.params.tipo === "map")?
                        <div className="modal-footer">
                            <button type="button" className="btn btn-link waves-effect" data-dismiss="modal">FECHAR</button>
                        </div>
                        :
                        <div className="modal-footer">
                            <button type="button" className="btn btn-link waves-effect" data-dismiss="modal">FECHAR</button>
                            <button type="button" className="btn btn-link waves-effect" onClick={() => { this.submit() }}>SALVAR ALTERAÇÕES</button>
                        </div>
                    }
                </div>
            </div>
        </div>);
    }
}

export class MontaConteudo extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.params.data;
    }

    handleChange = (index, event) => {
        var value = event.target.value;
        var name = event.target.id;
        
        this.setState({[name]: value});
        try {
          this.props.evChange({name: name, value: value});
        } catch(err) {
          console.error(`[${this.constructor.name}] ${err.message}`);
        }
    }

    atualizaHeight = () => {
        let altura = document.getElementById("bodyMapa").offsetHeight;
        document.getElementById("bodyMapa").style.height = altura + 10;
        document.getElementById("bodyMapa").style.height = altura - 10;
    }

    renderizaPorTipo = () => {
        if(this.props.params.tipo === "info"){
            return(
                <div>
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <h4><i className="material-icons">info_outline</i> - {this.props.params.data.name}</h4>
                    </div>
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <ul className="list-group">
                            {
                                this.props.fields.map((data) => {
                                    return(
                                        <a href="/" className="list-group-item" key={data.name} onClick={(e) => { e.preventDefault(); }}>
                                            <h4 className="list-group-item-heading">{data.name}</h4>
                                            <p className="list-group-item-text">{this.props.params.data[data.key]}</p>
                                        </a>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            );
        }else if(this.props.params.tipo === "edit") {
            return(
                <div>
                    <div className="col-sm-12 col-lg-12 col-md-12">
                        <h4><i className="material-icons">mode_edit</i> - {this.props.params.data.name}</h4>
                    </div>
                    <div className="col-sm-12 col-lg-12 col-md-12">
                            {
                                this.props.fields.map((data, index) => {
                                    return(
                                        (data.type !== "hidden")?
                                        <div className="form-group form-float" key={index}>
                                            <div className="form-line">
                                                <label>{data.name}</label>: <div></div>
                                                <input 
                                                    type={data.type} 
                                                    className="form-control" 
                                                    value={this.state[data.key]} 
                                                    name={data.key} 
                                                    id={data.key}
                                                    onChange={this.handleChange.bind(index, this)}
                                                    placeholder={data.name}/>
                                            </div>
                                        </div>: ''
                                    );
                                })
                            }
                    </div>
                </div>
            );
        }else{
            return(
                <div className="panel panel-danger">
                    <div className="panel-heading" role="tab">
                        <h4 className="panel-title">
                            <i className="material-icons">map</i> 
                            {this.props.params.localizacao[0].address}
                        </h4>
                    </div>
                    <div id="bodyMapa" ref="corpoMapa" className="panel-body">
                        <MyMapComponent
                            data={{isMarkerShown:true, lat:this.props.params.localizacao[0].lat, lng: this.props.params.localizacao[0].lng }}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBYSKy88iSIBmP5zBnwgiLhwvBDBAA3Ptw&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `400px` }} />}
                            mapElement={<div style={{ height: `100%` }}/>}
                        />
                    </div>
                </div>
            );
        }
    }

    render(){
        return(
            <div>
                {this.renderizaPorTipo()}
            </div>
        );
    }
}
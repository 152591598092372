export const GOOGLE_URL =
  "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyA1xxcGHwFGzIUcjEHJntFPlY_Fukq1M2s&libraries=geometry,drawing,places";
// export const REST_API = "https://farmazon-homolog.tk";
// export const REST_API = "https://farmazon.rio";
export const REST_API = "https://homolog.farmazon.com.br";
export const SOCKET_API = "wss://homolog.farmazon.com.br";
// export const REST_API = "http://192.168.0.44:8000";
// export const SOCKET_API = "ws://192.168.0.44:8000";
const CEP_API = "https://viacep.com.br";

/** @constant
    @type {Object.<string, Endpoint>}
*/
export const Endpoints = {
  login: new Endpoint("/api-token-auth/", "POST"),
  geolocate: new Endpoint("/api/address-geolocation/", "GET"),
  user: new Endpoint("/api/users/", "GET"),
  postUser: new Endpoint("/api/users/", "POST"),
  getClient: new Endpoint("/api/customers/", "GET"),
  getClientById: new Endpoint("/api/customers/{id}", "GET", REST_API, true),
  postClient: new Endpoint("/api/customers/", "POST"),
  searchClient: new Endpoint("/api/customers/search/", "GET"),
  updateClient: new Endpoint("/api/customers/{id}/", "PATCH", REST_API, true),
  userMe: new Endpoint("/api/users/me/", "GET"),
  createStore: new Endpoint("/api/stores/", "POST"),
  updateStore: new Endpoint("/api/stores/{id}/", "PATCH", REST_API, true),
  getStores: new Endpoint("/api/stores/", "GET"),
  searchStores: new Endpoint("/api/stores/search/", "GET"),
  cep: new Endpoint("/ws/{cep}/json/", "GET", CEP_API, true),
  postDeliveryAddress: new Endpoint("/api/delivery-addresses/", "POST"),
  getProductList: new Endpoint("/api/products/", "GET"),
  getProductById: new Endpoint("/api/products/{id}/", "GET", REST_API, true),
  getDeliveryAddress: new Endpoint("/api/delivery-addresses/", "GET"),
  findDeliveryAddress: new Endpoint("/api/delivery-addresses/search/", "GET"),
  getOrders: new Endpoint("/api/orders/", "GET"),
  searchOrders: new Endpoint("/api/orders/search/", "GET"),
  fullOrders: new Endpoint("/api/orders/{id}/", "GET", REST_API, true),
  createOrder: new Endpoint("/api/orders/", "POST"),
  deleteOrder: new Endpoint("/api/orders/{id}/", "DELETE", REST_API, true),
  createProductOrder: new Endpoint("/api/product-orders/", "POST"),
  searchOrderProposals: new Endpoint("/api/order-proposals/{order}", "GET", REST_API, true),
  postOrderOffer: new Endpoint("/api/order-offers/", "POST"),
  findOrderOffer: new Endpoint("/api/order-offers/", "GET"),
  processOrder: new Endpoint(
    "/api/orders/{id}/process/",
    "POST",
    REST_API,
    true
  ),
  closestStore: new Endpoint("/api/closest/store/", "GET"),
  invites: new Endpoint("/api/order-proposals/", "GET"),
  getStatusUpdates: new Endpoint("/api/status-updates/", "GET"),
  cancelOrder: new Endpoint("/api/orders/{id}/cancel/", "POST", REST_API, true),
  changeStore: new Endpoint("/api/orders/{id}/change/", "POST", REST_API, true)
};

export const Channels = {
  order: new Channel("/order/{id}/")
};

/**
 * Returns an API endpoint, described by its URL, method type and domain.
 * @param  {string} url - Endpoint portion of the URL
 * @param  {string} method - Method type (e.g. "POST")
 * @param  {?string} domain - Domain portion of the URL
 * @param  {Boolean} placedParams - True if the URL parameters should be placed.
 */
export function Endpoint(url, method, domain, hasPlacedParams) {
  // Never forget to use declare Endpoint as a NEW object.
  if (url.indexOf("http") >= 0) {
    this.url = url;
  } else {
    domain = domain || REST_API;
    this.url = domain + url;
  }

  this.hasPlacedParams = hasPlacedParams || false;
  this.method = method;
}

export function Channel(path) {
  this.url = `${SOCKET_API}${path}`;

  this.connect = params => {
    this.url = this.url.replace(/\{(\w+)\}/g, function(_, index) {
      return params[index];
    });
    this.socket = new WebSocket(this.url);

    this.socket.onopen = this.onopen;
    this.socket.onclose = this.onclose;
    this.socket.onerror = this.onerror;
    this.socket.onmessage = this.onmessage;
  };

  /**
   * @param  {} event
   */
  this.onopen = event => {};

  /**
   * @param  {} event
   */
  this.onclose = event => {};

  /**
   * @param  {} error
   */
  this.onerror = error => {};

  /**
   * @param  {} message
   */
  this.onmessage = message => {};

  this.close = () => {
    this.socket.close();
  };

  this.send = message => {
    this.socket.send(message);
  };
}

/**
 * Returns a API Request according to endpoint specifications with optional paramaters.
 * @param  {Endpoint} endpoint
 * @param  {JSON} params
 * @param  {string} auth
 * @returns {Request}
 */
export function APIRequest(endpoint, params, auth) {
  var url = endpoint.url;

  if (endpoint.hasPlacedParams) {
    // A linha abaixo troca tags de parâmetros pelos próprios parâmetros.
    url = url.replace(/\{(\w+)\}/g, function(_, index) {
      let replacement = params[index];
      delete params[index];
      return replacement;
    });
  } else if (endpoint.method === "GET") {
    let query = "";
    for (let key in params) {
      let encoded = encodeURIComponent(params[key]);
      query += `${key}=${encoded}&`;
    }
    url = `${url}?${query}`;
  }

  var request = {
    method: endpoint.method,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    body: endpoint.method === "GET" ? null : JSON.stringify(params)
  };

  if (auth) {
    request.headers.Authorization = `JWT ${auth}`;
  }

  return new Request(url, request);
}

/**
 * Sends the APIRequest defined by the request parameter.
 * @param  {Request} request
 * @param  {function} success
 * @param  {function} err
 */
export function APIFetch(request, success, err) {
  // É possível pedir o APIFetch com request = URL-string, desde que não tenha nenhum parâmetro extra!
  // para métodos GET.
  if (typeof request === "string") {
    let endpoint = new Endpoint(request, "GET");
    request = APIRequest(endpoint, {}, sessionStorage.apitoken);
  }

  fetch(request)
    .then(function(response) {
      // Se retornar 200, autenticação foi boa!
      // TODO: Se autenticação não foi boa, mostrar!
      if (response.ok) {
        return response.json();
      } else {
        var error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
    })
    .then(function(result) {
      // TODO: Probably should check if result actually is succesfull.
      if (success) {
        success(result);
      }
    })
    .catch(function(error) {
      //console.log(request);
      //console.log(error);
      if (!("response" in error)) {
        throw error;
      }

      let contentType = error.response.headers.get("Content-Type");
      if (contentType && contentType.indexOf("application/json") >= 0) {
        // Content is JSON, let parent handle error.
        error.response.json().then(json => {
          if (err) {
            err(json);
          } else {
            console.error(json);
          }
        });
      } else {
        // Content is HTML, unexpected error.
        error.response.text().then(text => {
          if (err) {
            err({
              fatal: "Unexpected error. Check logs for more details.",
              log: text
            });
          } else {
            console.error(text);
          }
        });
      }
    });
}

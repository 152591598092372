import {Field} from './Form.js';
import {Endpoint, Endpoints, APIRequest, APIFetch} from './Endpoints.js';
import {addMarker} from './google-maps/Maptools.js';


export class CEP extends Field {
  static defaultProps = {
    'fields': [
      {'name': "CEP", 'type': "number", 'key': "cep"},
    ]
  }
}

export class Address extends Field {
    static defaultProps = {
      'fields': [
        {'name': "CEP", 'type': "number", 'key': "cep"},
        {'name': "Logradouro", 'type': "text", 'key': "locale"},
        {'name': "Número", 'type': "text", 'key': "number"},
        {'name': "Complemento", 'type': "text", 'key': "complement"},
        {'name': "Bairro", 'type': "text", 'key': "region"},
        {'name': "Cidade", 'type': "text", 'key': "city"},
        {'name': "Estado", 'type': "text", 'key': "state"},
      ]
    }
}
  
export class StoreDetails extends Field {
    static defaultProps = {
      'fields': [
        {'name': "Nome da Farmácia", 'type': "text", 'key': "name"},
        {'name': "Telefone", 'type': "text", 'key': "telephone"},
        {'name': "Gerente", 'type': "text", 'key': "manager"},
      ]
    }
}

export class DeliveryAddress extends Field {
    static defaultProps = {
      'fields': [
        {'name': "Apelido", 'type': "text", 'key': "nickname"},
        {'name': "CEP", 'type': "number", 'key': "cep"},
        {'name': "Logradouro", 'type': "text", 'key': "locale"},
        {'name': "Número", 'type': "text", 'key': "number"},
        {'name': "Complemento", 'type': "text", 'key': "complement"},
        {'name': "Bairro", 'type': "text", 'key': "region"},
        {'name': "Cidade", 'type': "text", 'key': "city"},
        {'name': "Estado", 'type': "text", 'key': "state"},
      ]
    }
}
  
export class ClientDetails extends Field {
    static defaultProps = {
      'fields': [
        {'name': "Nome do Cliente", 'type': "text", 'key': "name"},
        {'name': "Telefone", 'type': "text", 'key': "telephone"},
      ]
    }
}

export class MarkerPicker extends Field {
  static defaultProps = {
    'fields': [
      {'name': "Selecione pelo Mapa", 'type': "text", 'key': "nickname" }
    ]
  }
  
  componentDidMount() {
    let request = APIRequest(Endpoints.getDeliveryAddress, {}, sessionStorage.apitoken)

    let markers = [];
    let map = window.map
    let google = window.google
    let bounds = new google.maps.LatLngBounds(null)
    APIFetch(request, addresses => {
      for (let i=0; i< addresses.length; i++) {
        // Para cada endereço busca o cliente que está no local.
        let endpoint = new Endpoint(addresses[i].customer, "GET")
        let clientRequest = APIRequest(endpoint, {}, sessionStorage.apitoken)
        APIFetch(clientRequest, customer => {
          let feature = {
            position: {lat: addresses[i].latitude, lng: addresses[i].longitude},
            type: "client",
            label: {
              text: addresses[i].nickname,
              color: 'white'
            }
          }
          let marker = addMarker(feature)
  
          // TODO: Make a html-snippets.js file, containing these infowindow templates.
          let infowindow = new google.maps.InfoWindow({
            content: `<b>${addresses[i].nickname}</b><br>
                      ${addresses[i].address}<br><br>

                      ${customer.name}<br>`
          })
          marker.addListener('click', () => {
            infowindow.open(map, marker)

            map.addListener('click', () => {
              infowindow.close()
            })

            this.select(addresses[i])
          })

          bounds.extend(marker.getPosition())  
          markers.push(marker)
          this.setState({markers: markers})
          map.fitBounds(bounds)
        }) 
      }
    })
  }

  select = (address) => {
    // FIXME: This feels VERY dirty.
    if (this.props.onChange) {
      this.props.onChange({name: 'customer', value: address.customer})
      this.props.onChange({name: 'delivery_address', value: address.url})
      this.props.onChange({name: 'nickname', value: address.nickname})
    }
  }
}
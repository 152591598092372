import React from "react";

const ModalTitle = ({ id, description, storeName, customerName }) => {
  let pedidoStatus = "";
  if (description) {
    pedidoStatus =
      " - " + description.charAt(0).toUpperCase() + description.slice(1);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 34px"
      }}
    >
      <h3 style={{ display: "flex", alignItems: "center" }}>
        <i className="material-icons" style={{ padding: "0, 6px" }}>
          assignment
        </i>{" "}
        <span>
          {id}
          {pedidoStatus}
        </span>
      </h3>
      <h4 style={{ display: "flex", alignItems: "center" }}>
        <span>{storeName}</span>{" "}
        <i className="material-icons" style={{ padding: "0 6px" }}>
          local_shipping
        </i>{" "}
        <span>{customerName}</span>
      </h4>
    </div>
  );
};

export default ModalTitle;

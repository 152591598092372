import React from "react";
import { Endpoints, APIRequest, APIFetch, SOCKET_API } from "../Endpoints";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";
import jquery from "jquery";
import "moment/locale/pt-br";

export class Timeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      statusUpdates: null,
      mensagem: {
        "em análise": [],
        completo: [],
        checkout: [],
        delivery: [],
        finalizado: [],
        flag: []
      },
      steps: {
        //0=Não Passou | 1=Passou
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        flag: false
      },
      cor: {
        0: "timeline-badge",
        1: "timeline-badge",
        2: "timeline-badge",
        3: "timeline-badge",
        4: "timeline-badge",
        flag: false
      }
    };
  }

  //Retorna as mensagens da api/status-updates
  getStatusUpdates = order => {
    let request = APIRequest(
      Endpoints.getStatusUpdates,
      { order: order, "log-type": "info", limit: 1000 },
      sessionStorage.apitoken
    );
    APIFetch(request, response => {
      if (response.count > 0) {
        this.setState({ statusUpdates: response });
        this.montaStatusUpdates();
      }
    });
  };

  //Atualiza cor do círculo do pedido
  alterColorPedido = steps => {
    let cores = this.state.cor;
    cores.flag = !this.state.cor.flag;
    let passos = steps;
    for (let i = 0; i < 5; i++) {
      if (i === 0) {
        if (passos[i] === 1 && passos[i + 1] === 1) {
          cores[i] = "timeline-badge success";
        } else if (passos[i] === 1 && passos[i + 1] === 0) {
          cores[i] = "timeline-badge warning";
        } else if (passos[i] === 1) {
          cores[i] = "timeline-badge warning";
        } else {
          cores[i] = "timeline-badge";
        }
      } else if (i === 4) {
        if (passos[i] === 1 && passos[i - 1] === 1) {
          cores[i] = "timeline-badge success";
        } else {
          cores[i] = "timeline-badge";
        }
      } else {
        if (passos[i] === 1 && passos[i - 1] === 1 && passos[i + 1] === 0) {
          cores[i] = "timeline-badge warning";
        } else if (
          passos[i] === 1 &&
          passos[i - 1] === 1 &&
          passos[i + 1] === 1
        ) {
          cores[i] = "timeline-badge success";
        } else {
          cores[i] = "timeline-badge";
        }
      }
    }
    this.setState({ cor: cores });
  };

  //Monta os dizeres que ficarão em todas as etapas da timeline
  montaStatusUpdates = status => {
    let steps = this.state.steps;
    steps.flag = !this.state.steps.flag;
    let mensagem = this.state.mensagem;
    mensagem.flag = !this.state.mensagem.flag;

    let identificador = {
      "em análise": 0,
      completo: 1,
      checkout: 2,
      delivery: 3,
      finalizado: 4
    };

    this.state.statusUpdates.results.map((data, index) => {
      if (
        statusPedidoIgnorados[0][data.status] === undefined &&
        data.status !== "" &&
        statusPedido[0][data.status] !== undefined
      ) {
        steps[identificador[statusPedido[0][data.status]]] = 1;
        mensagem[statusPedido[0][data.status]].push(
          <p key={index}>
            <small className="text-muted">
              <i className="fa fa-clock-o" />
              {this.formataData(
                this.state.statusUpdates.results[index].create_time
              )}
            </small>{" "}
            <br />
            {data.message}
          </p>
        );
      }
    });
    this.setState({ mensagem: mensagem, steps: steps });
    this.alterColorPedido(steps);
  };

  //Formata Data
  formataData = data => {
    moment.locale("pt-br");
    let hoje = moment(new Date());
    let dataMensagem = moment(data);

    //Caso tenha mais de um dia, será mostrado o dia e a hora da mensagem
    if (hoje.diff(dataMensagem, "days") > 0) {
      return moment(data).format("DD/MM/YYYY HH:mm:ss");
    } else {
      return moment(data).fromNow();
    }
  };

  //Socket responsável por receber novas atualizações de status
  socketTimeline = (pedido, flag) => {
    if (flag === "open") {
      const socketUrl = SOCKET_API + `/orders/${pedido}/`;
      let conexaoTimeline = new WebSocket(socketUrl);
      this.conexaoTimeline = conexaoTimeline;

      // this.conexaoTimeline.onopen = open => {}

      this.conexaoTimeline.onmessage = async message => {
        let json = JSON.parse(message.data);
        let data = json.state === "cancelado" ? json : !!json["status-updates"] && json["status-updates"][0];
        let steps = this.state.steps;
        steps.flag = !this.state.steps.flag;
        let mensagem = this.state.mensagem;
        //console.log(json)
        let identificador = {
          "em análise": 0,
          completo: 1,
          checkout: 2,
          delivery: 3,
          finalizado: 4
        };

        if (
          statusPedidoIgnorados[0][data.status] === undefined &&
          data.status !== "" &&
          statusPedido[0][data.status] !== undefined
        ) {
          steps[identificador[statusPedido[0][data.status]]] = 1;
          mensagem[statusPedido[0][data.status]].push(
            <p>
              <small className="text-muted">
                <i className="fa fa-clock-o" />
                {this.formataData(this.state.statusUpdates.create_time)}
              </small>{" "}
              <br />
              {data.message}
            </p>
          );
        }
        //console.log(mensagem[statusPedido[0][data.state]])

        if (data.state === "cancelado" && mensagem[statusPedido[0][data.state]].length === 0) {
          // console.log(mensagem[statusPedido[0][data.state]])
          // console.log(<p>
          //   <small className="text-muted">
          //     <i className="fa fa-clock-o" />
          //     {this.formataData(this.state.statusUpdates.create_time)}
          //   </small>{" "}
          //   <br />
          //   {data.state}
          // </p>)

          await mensagem[statusPedido[0][data.state]].push(
            <p>
              <small className="text-muted">
                <i className="fa fa-clock-o" />
                {this.formataData(this.state.statusUpdates.create_time)}
              </small>{" "}
              <br />
              {data.state}
            </p>
          );
        }
        //console.log(mensagem[statusPedido[0][data.state]], data.state, statusPedido[0][data.state], 'asuahhs')
        this.setState({ mensagem: mensagem, steps: steps });
        this.alterColorPedido(steps);
      };

      this.conexaoTimeline.onclose = () => { };
    } else {
      this.conexaoTimeline.close();
    }
  };

  componentDidMount() {
    if (this.props.order !== undefined && this.props.order !== null) {
      this.getStatusUpdates(this.props.order);
      this.socketTimeline(this.props.order, "open");
    }
  }

  componentWillUnmount() {
    this.conexaoTimeline.close();
  }

  render() {
    jquery(".boxPedidoTimeline").waitMe("hide");
    return this.state.statusUpdates !== null ? (
      <ul className="timeline">
        {componentes.map((dados, indice) => {
          return (
            <li className={dados.classeLi} key={indice}>
              <div className={this.state.cor[indice]}>
                <i className={dados.icone} aria-hidden="true" />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h5 className="timeline-title">{dados.nome}</h5>
                </div>
                <div className="timeline-body">
                  {this.state.mensagem[dados.status]}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    ) : (
        <div>Carregando...</div>
      );
  }
}

//Etapas da Timeline
const componentes = [
  {
    classeLi: "",
    nome: "Pedido Efetuado",
    icone: "fa fa-shopping-cart",
    status: "em análise"
  },
  {
    classeLi: "timeline-inverted",
    nome: "Pagamento",
    icone: "fa fa-credit-card",
    status: "completo"
  },
  {
    classeLi: "",
    nome: "Farmácia Selecionada",
    icone: "fa fa-certificate",
    status: "checkout"
  },
  {
    classeLi: "timeline-inverted",
    nome: "Entrega",
    icone: "fa fa-motorcycle",
    status: "delivery"
  },
  {
    classeLi: "",
    nome: "Finalizado",
    icone: "fa fa-check-square-o",
    status: "finalizado"
  }
];

/* 
    Status pedido x Status Timeline

    Iremos receber um status de cada mensagem do servidor, esse status
    será encaixado em alguma fase da timeline (em análise, completo, checkout,
    delivery e finalizado)
*/
const statusPedido = [
  {
    vazio: "em análise",
    completo: "completo",
    agregando: "em análise",
    "aguardando ofertas": "em análise",
    "em análise": "em análise",
    "aguardando confirmação": "em análise",
    "aguardando opções de pagamento": "completo",
    "em validação": "completo",
    checkout: "checkout",
    "aguardando remessa": "delivery",
    "a caminho": "delivery",
    entregue: "finalizado",
    cancelado: "em análise"
  }
];
//São os status que não irão entrar na timeline
const statusPedidoIgnorados = [
  {
    //'completo': 'em análise',
    agregando: "em análise"
    //'vazio': 'em análise'
  }
];

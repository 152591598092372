import React from "react";
import {
  MultiLocationWrapper,
  MultiLocationPaper,
  Header,
  CloseText,
  OpenText,
  Content,
  List,
  Divider
} from "./MultiLocation.styles";
import { MARKER_TYPE } from "../../../../enum/MarkerType";
import ListItem from "./ListItem";

class MultiLocation extends React.Component {
  state = {
    isOpen: true
  };

  toggleModal = () =>
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  renderItems = () =>
    this.props.markers.map(m => {
      const obj = JSON.parse(m.getTitle());

      return (
        <ListItem
          key={obj.id}
          onClick={() => this.props.onClick(obj.id, obj.type)}
          {...obj}
        />
      );
    });

  render() {
    const { isOpen } = this.state;

    return (
      <MultiLocationWrapper>
        <MultiLocationPaper>
          {isOpen ? (
            <React.Fragment>
              <Header>
                <h5>Marcadores do grupo</h5>
                <CloseText onClick={this.toggleModal}>FECHAR</CloseText>
              </Header>

              <Divider />

              <Content>
                <List>{this.renderItems()}</List>
              </Content>
            </React.Fragment>
          ) : (
            <Header>
              <OpenText onClick={this.toggleModal}>ABRIR</OpenText>
            </Header>
          )}
        </MultiLocationPaper>
      </MultiLocationWrapper>
    );
  }
}

export default MultiLocation;

import React, {Component} from 'react';
import moment from 'moment';
import jquery from 'jquery';
window.$ = window.jQuery = jquery;
window.moment = moment;
require('adminbsb-materialdesign/plugins/bootstrap-material-datetimepicker/css/bootstrap-material-datetimepicker.css');
require('adminbsb-materialdesign/plugins/bootstrap-material-datetimepicker/js/bootstrap-material-datetimepicker');

export class DataBusca extends Component {
    componentDidMount() {
        if (this.props.campo === "de") {
            this.initDateTimePicker('#dataDe');
        }else{
            this.initDateTimePicker('#dataAte');
        }
    }

    //Método responsável por ligar o datetimepicker a algum input
    initDateTimePicker = (campo) => {
        window.jQuery(campo).bootstrapMaterialDatePicker({ format: 'YYYY-MM-DDHH:mm', lang: 'br', weekStart: 1, cancelText: 'CANCELAR' });
    }
    
    render(){
        if(this.props.campo === "de"){
            return (<input type="text" id="dataDe" ref="campoDe" placeholder="De: XX/XX/XXXX XX:XX" className="form-control" />)
        }else{
            return (<input type="text" id="dataAte" ref="campoAte" placeholder="Até: XX/XX/XXXX XX:XX" className="form-control" />)
        }
    }
}
import React, { Component } from "react";
import { Endpoints, APIRequest, APIFetch } from "../Endpoints";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";
import { Modal } from "../Modal";
import orderBy from "lodash/orderBy";
import { Tabela } from "../Tabela";
import { DataBusca } from "../DataBusca";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { Aubergine } from "../google-maps/themes";
import cliente from "../google-maps/icons/client-64.png";
import jquery from "jquery";
import "sweetalert2/dist/sweetalert2.min.css";
window.$ = window.jQuery = jquery;
require("adminbsb-materialdesign/plugins/jquery-inputmask/jquery.inputmask.bundle");

const MyMapComponent = withScriptjs(
  withGoogleMap(props => {
    let ObjCenter = { lat: props.data.lat, lng: props.data.lng };
    return (
      <GoogleMap
        defaultZoom={16}
        defaultCenter={ObjCenter}
        defaultOptions={{ styles: Aubergine }}
      >
        {props.data.isMarkerShown && (
          <Marker
            position={{ lat: props.data.lat, lng: props.data.lng }}
            defaultIcon={cliente}
          />
        )}
      </GoogleMap>
    );
  })
);

export class ListaClientes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itensPorPagina: 5,
      qtdClientes: 0,
      pagina: 0,
      listagemDeClientes: null,
      filtroSelecionado: "name",
      showAlert: false,
      tituloAlert: "",
      typeAlert: "info",
      mensagemAlert: "",
      modal: null,
      mapa: null,
      mapaToggle: false,
      mapaEndereco: "",
      metodoListagem: "listar",
      filtroSwitch: false
    };

    this.listarClientes(this.state.itensPorPagina, this.state.pagina);
    window.jQuery(document).ready(function() {
      window.jQuery(".selectpicker").selectpicker({
        style: "btn-default",
        size: 4
      });
      window.jQuery("body").tooltip({ selector: "[data-toggle=tooltip]" });
      window.jQuery(":input").inputmask();
    });
  }

  //Método responsável por trazer a listagem de clientes
  listarClientes = (itensPorPagina, pagina) => {
    var request = APIRequest(
      Endpoints.searchClient,
      { limit: itensPorPagina, offset: itensPorPagina * pagina },
      sessionStorage.apitoken
    );
    APIFetch(request, response => {
      if (response.count > 0) {
        let listagem = orderBy(response.results, ["id"], ["desc"]);
        this.setState({
          qtdClientes: response.count,
          listagemDeClientes: listagem
        });
      }
    });
  };

  //Método responsável pelo evento de seleção do filtro
  selecionaFiltro = event => {
    this.setState({ filtroSelecionado: event.target.value });
  };

  //Remove o filtro de busca
  removerFiltro = () => {
    this.refs.campoBusca.value = "";
    this.setState({ filtroSwitch: false, pagina: 0, metodoListagem: "listar" });
    this.listarClientes(this.state.itensPorPagina, 0);
  };

  //Método responsável por efetuar uma busca
  efetuarBusca = (itensPorPagina, pagina) => {
    let filtro = this.refs.filtroBusca.value;
    let request;
    if (filtro === "create_time") {
      let campoDe = this.refs.campoDe.refs.campoDe.value;
      let campoAte = this.refs.campoAte.refs.campoAte.value;
      request = APIRequest(
        Endpoints.searchStores,
        {
          created_before: campoDe,
          created_after: campoAte,
          limit: itensPorPagina,
          offset: itensPorPagina * pagina
        },
        sessionStorage.apitoken
      );
    } else {
      request = APIRequest(
        Endpoints.searchClient,
        {
          [filtro]: this.refs.campoBusca.value,
          limit: itensPorPagina,
          offset: itensPorPagina * pagina
        },
        sessionStorage.apitoken
      );
    }
    APIFetch(request, response => {
      if (response.count > 0) {
        let listagem = orderBy(response.results, ["id"], ["desc"]);
        this.setState({
          qtdClientes: response.count,
          listagemDeClientes: listagem,
          metodoListagem: "busca",
          filtroSwitch: true
        });
      }
    });
  };

  eventCamposCad = event => {
    let key = event.target.id;
    let value = event.target.value;

    if (key === "telephoneCad") {
      //Retirando a máscara do telefone
      var telefoneCad = document.getElementById(key);
      value = telefoneCad.inputmask.unmaskedvalue();
    }
    this.setState({ [key]: value });
  };

  //Método responsável por cadastrar um cliente
  cadCliente = () => {
    let erro = false;
    let contErro = 0;
    let camposErro = "";

    if (
      this.state.usernameCad === "" ||
      this.state.usernameCad === null ||
      this.state.usernameCad === undefined
    ) {
      camposErro = camposErro + "username";
      erro = true;
      contErro++;
    }

    if (
      this.state.emailCad === "" ||
      this.state.emailCad === null ||
      this.state.emailCad === undefined
    ) {
      if (camposErro !== "") {
        camposErro = camposErro + ",e-mail";
      } else {
        camposErro = camposErro + "e-mail";
      }
      erro = true;
      contErro++;
    }

    if (
      this.state.nameCad === "" ||
      this.state.nameCad === null ||
      this.state.nameCad === undefined
    ) {
      if (camposErro !== "") {
        camposErro = camposErro + ",nome";
      } else {
        camposErro = camposErro + "nome";
      }
      erro = true;
      contErro++;
    }

    if (
      this.state.telefoneCad === "" ||
      this.state.telefoneCad === null ||
      this.state.telefoneCad === undefined
    ) {
      if (camposErro !== "") {
        camposErro = camposErro + ",telefone";
      } else {
        camposErro = camposErro + "telefone";
      }
      erro = true;
      contErro++;
    }

    if (!erro) {
      //Montando o Objeto para o envio do cadastro
      let objetoCadastroUser = {
        username: this.state.usernameCad,
        email: this.state.emailCad
      };

      //Primeira requisição: Cadastro do Usuário
      var requestCadUser = APIRequest(
        Endpoints.postUser,
        objetoCadastroUser,
        sessionStorage.apitoken
      );
      APIFetch(requestCadUser, resultCadUser => {
        if (resultCadUser.url !== undefined) {
          //Montando o Objeto para o cadastro do cliente
          let objetoCadastroCliente = {
            email: this.state.emailCad,
            name: this.state.nameCad,
            telephone: this.state.telephoneCad,
            user: resultCadUser.url
          };

          //Segunda Requisição: Cadastro do Cliente
          var requestCadCliente = APIRequest(
            Endpoints.postClient,
            objetoCadastroCliente,
            sessionStorage.apitoken
          );
          APIFetch(requestCadCliente, resultCadCliente => {
            if (resultCadCliente.name !== undefined) {
              this.setState({ showAlert: true });
              this.setState({ tituloAlert: "Cliente cadastrado com sucesso!" });
              this.setState({ typeAlert: "success" });
              this.setState({
                mensagemAlert:
                  "O cliente foi cadastrado com sucesso, para conferir os dados do mesmo, confira na lista abaixo."
              });
              this.listarClientes();

              //Limpar os campos e fechar o modal
              this.setState({ usernameCad: "" });
              this.setState({ nameCad: "" });
              this.setState({ emailCad: "" });
              this.setState({ telephoneCad: "" });
              window.jQuery("#modalCadCli").modal("hide"); //Fechar o modal
            } else {
              this.setState({ showAlert: true });
              this.setState({ tituloAlert: "Oops.." });
              this.setState({ typeAlert: "error" });
              this.setState({
                mensagemAlert:
                  "Tivemos um problema ao cadastrar o cliente, o nome de usuário e e-mail utilizados no cadastro já foram utilizados."
              });
            }
          });
        } else {
          this.setState({ showAlert: true });
          this.setState({ tituloAlert: "Oops.." });
          this.setState({ typeAlert: "error" });
          this.setState({
            mensagemAlert:
              "Tivemos um problema ao cadastrar o usuário, tente novamente."
          });
        }
      });
    } else {
      this.setState({ showAlert: true });
      this.setState({ tituloAlert: "Atenção!" });
      this.setState({ typeAlert: "warning" });

      if (contErro > 1) {
        this.setState({
          mensagemAlert: "Os campos " + camposErro + " são obrigatórios!"
        });
      } else {
        this.setState({
          mensagemAlert: "O campo " + camposErro + " é obrigatório!"
        });
      }

      erro = false;
    }
  };

  callModal = params => {
    const conteudoEstrutura = params;
    if (params.tipo === "map") {
      //Buscar LatLng
      if (Object.keys(conteudoEstrutura.data.delivery_addresses).length <= 0) {
        this.setState({ showAlert: true });
        this.setState({ tituloAlert: "Oops.." });
        this.setState({ typeAlert: "warning" });
        this.setState({ mensagemAlert: "Nenhum endereço encontrado." });
      } else {
        let objetoLocalizacao = [];
        conteudoEstrutura.data.delivery_addresses.map(data => {
          var request = APIRequest(
            Endpoints.getDeliveryAddress,
            { url: params.data.delivery_addresses[0] },
            sessionStorage.apitoken
          );
          APIFetch(request, response => {
            objetoLocalizacao.push({
              address: response[0].address,
              lat: response[0].latitude,
              lng: response[0].longitude
            });
            conteudoEstrutura["localizacao"] = objetoLocalizacao;
            this.setState({
              modal: (
                <Modal
                  key={params.data.id}
                  params={conteudoEstrutura}
                  localizacao={objetoLocalizacao}
                />
              )
            });
            window.jQuery(document).ready(function() {
              window.jQuery("#modalAdmin").modal("show");
            });
          });
        });
      }
    } else {
      this.setState({
        modal: <Modal key={params.data.id} params={conteudoEstrutura} />
      });
      window.jQuery(document).ready(function() {
        window.jQuery("#modalAdmin").modal("show");
      });
    }
  };

  //Chama o Mapa
  verMapa = props => {
    this.setState({
      mapaToggle: true,
      mapaEndereco: props.address,
      mapa: (
        <MyMapComponent
          data={{ isMarkerShown: true, lat: props.lat, lng: props.lng }}
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBYSKy88iSIBmP5zBnwgiLhwvBDBAA3Ptw&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      )
    });
  };

  //Atualiza a quantidade de itens por página
  eventItensPorPagina = event => {
    this.setState({ itensPorPagina: event.target.value });
    if (this.state.metodoListagem === "busca") {
      this.efetuarBusca(event.target.value, this.state.pagina);
    } else {
      this.listarClientes(event.target.value, this.state.pagina);
    }
  };

  //Atualiza a página atual
  alteraPagina = ({selected}) => {
    this.setState({ pagina: selected });
    if (this.state.metodoListagem === "busca") {
      this.efetuarBusca(this.state.itensPorPagina, selected);
    } else {
      this.listarClientes(this.state.itensPorPagina, selected);
    }
  };

  render() {
    //Dados que serão utilizados para montar a tabela
    const dados = {
      cabecalho: [
        {
          nome: "#",
          tipo: "campo",
          classe: "bg-grey",
          classeCol: "col-red",
          indice: "",
          indexacao: "id"
        },
        {
          nome: "NOME",
          tipo: "campo",
          classe: "bg-red",
          classeCol: "",
          indice: "",
          indexacao: "name"
        },
        {
          nome: "TELEFONE",
          tipo: "campo",
          classe: "bg-red",
          classeCol: "",
          indice: "",
          indexacao: "telephone"
        },
        {
          nome: "E-MAIL",
          tipo: "campo",
          classe: "bg-red",
          classeCol: "",
          indice: "",
          indexacao: "email"
        },
        {
          nome: "DATA DE CADASTRO",
          tipo: "data",
          classe: "bg-red",
          classeCol: "",
          indice: "",
          indexacao: "create_time"
        },
        {
          nome: "OPÇÕES",
          tipo: "options",
          classe: "bg-red",
          classeCol: "",
          indice: "",
          indexacao: ""
        }
      ],
      tipo: "clientes",
      qtdItens: this.state.qtdClientes,
      itensPorPagina: this.state.itensPorPagina,
      pagina: this.state.pagina
    };
    let metodosTabela = {
      callModal: this.callModal,
      eventItensPorPagina: this.eventItensPorPagina,
      alteraPagina: this.alteraPagina,
      verMapa: this.verMapa
    };
    return (
      <div>
        <div className="card">
          {this.state.mapaToggle ? (
            <div className="header">
              <h2>
                <button
                  className="btn btn-default"
                  onClick={() => {
                    this.setState({ mapaToggle: false, mapa: null });
                    window.jQuery(document).ready(function() {
                      window.jQuery(".selectpicker").selectpicker({
                        style: "btn-default",
                        size: 4
                      });
                    });
                  }}
                >
                  <i className="material-icons">keyboard_backspace</i>
                </button>
                &nbsp;<b>Ver no Mapa:</b> {this.state.mapaEndereco}
              </h2>
            </div>
          ) : (
            <div>
              <div className="row clearfix">
                <div className="col-md-12">
                  <div className="col-md-12">
                    <h2>
                      <i className="material-icons">format_list_bulleted</i>{" "}
                      Listagem de Clientes
                      <ol className="breadcrumb">
                        <li>
                          <a href="/">
                            <i className="material-icons">home</i> Home
                          </a>
                        </li>
                        <li>Clientes</li>
                        <li>Listar</li>
                      </ol>
                    </h2>
                  </div>
                </div>
              </div>
              <hr style={{ margin: 0, padding: 0 }} />
            </div>
          )}
          <div className="body table-responsive">
            {this.state.mapaToggle ? (
              this.state.mapa
            ) : (
              <div>
                <div className="row clearfix">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Buscar por:</label>
                      <select
                            style={{
                              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12)',
                              borderRadius: 2,
                              border: 'none',
                              fontSize: 13,
                              width: 150,
                              height: 38,
                              marginLeft: 20
                            }}
                        ref="filtroBusca"
                        onChange={this.selecionaFiltro.bind(this)}
                      >
                        <option value="name">Nome</option>
                        <option value="email">E-mail</option>
                        <option value="id">ID</option>
                        <option value="create_time">Data de Cadastro</option>
                        <option value="telephone">Telefone</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    {this.state.filtroSelecionado !== "create_time" ? (
                      <div>
                        <div className="form-group">
                          <div className="form-line">
                            <input
                              type="text"
                              id="campo-busca"
                              ref="campoBusca"
                              className="form-control"
                              placeholder="Buscar..."
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="form-line">
                              <DataBusca campo="de" ref="campoDe" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="form-line">
                              <DataBusca campo="ate" ref="campoAte" />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-sm-3">
                    <div className="form-group">
                      <div className="form-line">
                        <button
                          className="btn btn-block btn-lg bg-red waves-effect"
                          onClick={() => {
                            this.efetuarBusca(this.state.itensPorPagina, 0);
                          }}
                        >
                          <i className="material-icons">search</i>
                          <span>Buscar</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.filtroSwitch ? (
                  <div className="alert alert-warning">
                    Você está buscando por: "{this.refs.campoBusca.value}".
                    <span className="pull-right">
                      <b>
                        <a
                          href="/"
                          onClick={e => {
                            e.preventDefault();
                            this.removerFiltro();
                          }}
                        >
                          Clique aqui
                        </a>{" "}
                        para remover o filtro.
                      </b>
                    </span>
                  </div>
                ) : (
                  <div />
                )}
                <Tabela
                  conteudo={this.state.listagemDeClientes}
                  dados={dados}
                  metodos={metodosTabela}
                />
              </div>
            )}
            {this.state.modal}
            {/* MODAL CADASTRO DE CLIENTES */}
            <div className="modal fade" id="modalCadCli" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                      <i className="material-icons">person_add</i> Cadastro de
                      Clientes
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <h4>Dados de Acesso:</h4>

                        <div className="form-group">
                          <div className="form-line">
                            <input
                              type="text"
                              className="form-control"
                              id="usernameCad"
                              placeholder="Username"
                              value={this.state.usernameCad}
                              onChange={this.eventCamposCad.bind(this)}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-line">
                            <input
                              type="text"
                              className="form-control"
                              id="emailCad"
                              placeholder="E-mail"
                              value={this.state.emailCad}
                              onChange={this.eventCamposCad.bind(this)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <h4>Dados Pessoais:</h4>

                        <div className="form-group">
                          <div className="form-line">
                            <input
                              type="text"
                              className="form-control"
                              id="nameCad"
                              placeholder="Nome"
                              value={this.state.nameCad}
                              onChange={this.eventCamposCad.bind(this)}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-line">
                            <input
                              type="text"
                              className="form-control"
                              id="telephoneCad"
                              placeholder="Telefone"
                              value={this.state.telefoneCad}
                              onChange={this.eventCamposCad.bind(this)}
                              data-inputmask="'mask': '+99(99)9999[9]-9999'"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className="btn btn-link waves-effect"
                      data-dismiss="modal"
                    >
                      Cancelar
                    </button>
                    <button
                      className="btn btn-link waves-effect"
                      onClick={() => {
                        this.cadCliente();
                      }}
                    >
                      Salvar Alterações
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fixed-action-btn">
          <button
            type="button"
            className="btn btn-danger btn-circle-lg waves-effect waves-circle waves-float"
            data-toggle="tooltip"
            title="Atualizar Lista"
            style={botaoRefresh}
            onClick={() =>
              this.listarClientes(this.state.itensPorPagina, this.state.pagina)
            }
          >
            <i className="material-icons">refresh</i>
          </button>
          <button
            type="button"
            className="btn btn-danger btn-circle-lg waves-effect waves-circle waves-float"
            data-toggle="tooltip"
            title="Cadastrar Cliente"
            style={botaoMais}
            onClick={() => {
              window.jQuery("#modalCadCli").modal("show");
            }}
          >
            <i className="material-icons">add</i>
          </button>
        </div>
        <SweetAlert
          show={this.state.showAlert}
          title={this.state.tituloAlert}
          type={this.state.typeAlert}
          text={this.state.mensagemAlert}
          onConfirm={() => this.setState({ showAlert: false })}
        />
      </div>
    );
  }
}

const botaoMais = {
  position: "fixed",
  right: 23,
  bottom: 23,
  marginBottom: 0,
  zIndex: 997
};

const botaoRefresh = {
  position: "fixed",
  right: 23,
  bottom: 23,
  marginBottom: 60,
  zIndex: 997
};

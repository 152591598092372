import React, { Component } from "react";
import { Endpoints, APIRequest, APIFetch, SOCKET_API } from "../Endpoints";
import orderBy from "lodash/orderBy";
import "../css/timeline.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "font-awesome/css/font-awesome.min.css";
import swal from "sweetalert2";
import { Tabela } from "../Tabela";
import jquery from "jquery";
import "adminbsb-materialdesign/plugins/waitme/waitMe.css";
import "adminbsb-materialdesign/plugins/waitme/waitMe.js";
// import Modal from "../components/Modal";
import DetailsPage from "./detalhes/DetailsPage";

window.$ = window.jQuery = jquery;

export class ListaPedidos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      qtdPedidos: 0,
      listagemDePedidos: null,
      itensPorPagina: 5,
      pagina: 0,
      paginacao: null,
      filtroSwitch: false,
      filtroSelecionado: "name",
      campoBusca: null,
      detalhesPedido: null,
      statusPedido: null,
      descricaoPedido: null,
      pedidoSelecionado: 0,
      conexaoPedido: null,
      listagemDeFarmacias: [],
      metodoListagem: "listar",
      isModalOpen: false,
      idPedido: null,
      pedido: null
    };

    window.jQuery(document).ready(function() {
      window.jQuery("body").tooltip({ selector: "[data-toggle=tooltip]" });

      window.jQuery(":input").inputmask();
    });
  }

  //Método responsável pelo evento de seleção do filtro
  selecionaFiltro = event => {
    this.setState({ filtroSelecionado: event.target.value });
  };

  //Remove o filtro de busca
  removerFiltro = () => {
    this.refs.campoBusca.value = "";
    this.setState({ filtroSwitch: false, pagina: 0, metodoListagem: "listar" });
    this.listarPedidos(this.state.itensPorPagina, 0);
  };

  //Método responsável por efetuar uma busca
  efetuarBusca = (itensPorPagina, pagina) => {
    let filtro = this.refs.filtroBusca.value;

    let request = APIRequest(
      Endpoints.searchOrders,
      {
        [filtro]: this.refs.campoBusca.value,
        statemin: "completo",
        limit: itensPorPagina,
        offset: itensPorPagina * pagina
      },
      sessionStorage.apitoken
    );
    APIFetch(request, result => {
      let listagem = orderBy(result.results, ["id"], ["desc"]);
      this.setState({
        qtdPedidos: result.count,
        listagemDePedidos: listagem,
        metodoListagem: "busca",
        filtroSwitch: true
      });
    });
  };

  //Retorna os pedidos do sistema
  listarPedidos = (itensPorPagina, pagina) => {
    var request = APIRequest(
      Endpoints.searchOrders,
      {
        statemin: "novo",
        limit: itensPorPagina,
        offset: itensPorPagina * pagina
      },
      sessionStorage.apitoken
    );
    APIFetch(request, response => {
      let listagem = response.results;
 
      if (response.count > 0) {
        listagem = orderBy(listagem, ["id"], ["desc"]);
      }
      this.setState({
        qtdPedidos: response.count,
        listagemDePedidos: listagem
      });
    });
  };

  //Deleta um pedido
  delPedido = dadosPedido => {
    swal({
      title: `Você tem certeza?`,
      text: "Esta ação não poderá ser desfeita!",
      type: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Deletar!"
    }).then(function() {
      let requestDel = APIRequest(
        Endpoints.deleteOrder,
        { id: dadosPedido.id },
        sessionStorage.apitoken
      );
      APIFetch(
        requestDel,
        respDel => {
          swal(
            "Deletado!",
            `Pedido #${dadosPedido.id} deletado com sucesso.`,
            "success"
          );
          this.listarPedidos(this.state.itensPorPagina, this.state.pagina);
        },
        error => {
          if (error.response.status === 500) {
            swal(
              `Pedido #${dadosPedido.id} não foi deletado`,
              `Pedido #${
                dadosPedido.id
              } não pode ser deletado no status atual(<b>${
                dadosPedido.state
              }</b>).`,
              "error"
            );
          }
        }
      );
    });
  };

  callModal = pedido => {
    this.setState({
      isModalOpen: true,
      idPedido: pedido.id,
      pedido
    });
  };

  closePage = () => this.setState({ idPedido: null });

  //Atualiza o status do pedido
  attStatusPedido = dados => {
    this.setState({
      statusPedido: dados.estado,
      descricaoPedido: "em andamento"
    });
  };

  //Atualiza a listagem de lojas de acordo com o status do pedido
  atListagemLojas = order => {
    let tabela = { state: null, results: null };
    tabela.state = order.state;

    let req = APIRequest(
      Endpoints.searchOrderProposals,
      { order: order.id, limit: 1000 },
      sessionStorage.apitoken
    );
    /*if (order.state === "aguardando ofertas") {
            req = APIRequest(Endpoints.searchOrderProposals, { order: order.id, limit: 1000 }, sessionStorage.apitoken);
        } else if (order.state === "em análise") {
            req = APIRequest(Endpoints.findOrderOffer, { order: order.id, limit: 1000 }, sessionStorage.apitoken);
        }*/
    APIFetch(req, res => {
      if (res) {
        if (res.count > 0) {
          let farmacias = JSON.parse(JSON.stringify(res));
          /*tabela.results = farmacias.results;
                    let Listafarmacias = [];
                    farmacias.results.map((value, index) => {
                        Listafarmacias.push(value.store);
                    })*/
          this.setState({ listagemDeFarmacias: farmacias.results });
        }
      }
    });
  };

  //Método responsável pelos eventos do socket de detalhes de um pedido.
  //flag="open" / flag="close"
  socketPedido = (idPedido, flag) => {
    if (flag === "open") {
      let conexao = new WebSocket(SOCKET_API + `/orders/${idPedido}/`);
      this.setState({ conexaoPedido: conexao });
      window.conexaoPedido = conexao;
      conexao.onopen = dadosOpen => {
        conexao.send("update");
      };

      conexao.onmessage = message => {
        let msg = JSON.parse(message.data);
        //console.log(msg, 'ausuha')
        //Caso o pedido esteja expirado, retirar da listagem fora do Modal
        if (msg.type === "order-expired") {
          let lista = JSON.parse(JSON.stringify(this.state.listagemDePedidos));
          lista.math = Math.random();
          lista.map((linha, indice) => {
            if (linha.id === idPedido) {
              lista.splice(indice, 1);
              this.setState({ listagemDePedidos: lista });
            }
          });
          this.attStatusPedido({
            estado: "Pedido Expirado",
            description: "cancelado"
          }); //Chama a função para atualizar o status e descrição do pedido
        } else if (msg.type === "proposal-update") {
          //Buscar pela loja escolhida e colocar um "check" ao lado do nome da loja
          let listagem = JSON.parse(
            JSON.stringify(this.state.listagemDeFarmacias)
          );
          this.state.listagemDeFarmacias.map((dados, indice) => {
            if (dados.id === msg.id) {
              listagem[indice].state = msg.state; //Atualiza o Status
            }
          });
          this.setState({ listagemDeFarmacias: listagem });
        } else {
          if ("order" in msg) {
            let estado = msg.order.state;
            let description = msg.order.description;
            this.attStatusPedido({ estado: estado, description: description }); //Chama a função para atualizar o status e descrição do pedido
            this.atListagemLojas(msg.order);
          } else if ("state" in msg && "description" in msg) {
            let estado = msg.state;
            let description = msg.description;
            this.attStatusPedido({ estado: estado, description: description }); //Chama a função para atualizar o status e descrição do pedido
            //this.atListagemLojas(idPedido); //Chama a Função para atualizar a listagem de lojas
          }
        }
      };

      conexao.onclose = () => {};
    } else {
      this.setState({
        statusPedido: null,
        listagemDeFarmacias: [],
        detalhesPedido: null
      });
      let conexao = this.state.conexaoPedido;
      conexao.close();
    }
  };

  componentWillMount() {
    this.listarPedidos(this.state.itensPorPagina, this.state.pagina);
  }

  //Inicia o websocket para verificar se possui atualização em algum dos pedidos
  componentDidMount() {
    this.conexao = new WebSocket(SOCKET_API + "/orders/");

    this.conexao.onerror = function(error) {
      swal(
        "Atenção!",
        "Ocorreu um problema ao sincronizar os dados com o servidor da SpeedFarma, atualize a página e tente novamente.",
        "warning"
      );
    };

    this.conexao.onopen = open => {};

    //Quando um pedido for alterado/recebido
    this.conexao.onmessage = message => {
      let msg = JSON.parse(message.data);
      let encontrou = false;
      if ("order" in msg) {
        this.state.listagemDePedidos.map((dados, indice) => {
          //Caso o pedido já exista, a listagem será atualizada com o pedido alterado.
          if (dados.id === msg.order.id) {
            //Atualizar a lista
            encontrou = true;

            let novaListagem = JSON.parse(
              JSON.stringify(this.state.listagemDePedidos)
            );
            novaListagem[indice] = msg.order;
            novaListagem[indice].id = msg.order.id;
            novaListagem = orderBy(novaListagem, ["id"], ["desc"]);
            this.setState({ listagemDePedidos: novaListagem });
          }
        });
      }
      //Caso não tenha encontrado nenhum na listagem, a mesma receberá o novo pedido
      if (!encontrou) {
        if ("order" in msg) {
          if (this.state.pagina === 0) {
            let novaListagem = JSON.parse(
              JSON.stringify(this.state.listagemDePedidos)
            );
            if (novaListagem.length >= this.state.itensPorPagina) {
              novaListagem.pop();
            }

            novaListagem.unshift(msg.order);
            this.setState({ listagemDePedidos: novaListagem });
          }
        }
      }
    };
  }

  //Atualiza a quantidade de itens por página
  eventItensPorPagina = event => {
    this.setState({ itensPorPagina: event.target.value });
    if (this.state.metodoListagem === "busca") {
      this.efetuarBusca(event.target.value, this.state.pagina);
    } else {
      this.listarPedidos(event.target.value, this.state.pagina);
    }
  };

  //Atualiza a página atual
  alteraPagina = ({selected}) => {
    this.setState({ pagina: selected });
    if (this.state.metodoListagem === "busca") {
      this.efetuarBusca(this.state.itensPorPagina, selected);
    } else {
      this.listarPedidos(this.state.itensPorPagina, selected);
    }
  };

  render() {
    const { isModalOpen, idPedido, pedido } = this.state;
    let metodosTabela = {
      callModal: this.callModal,
      delPedido: this.delPedido,
      eventItensPorPagina: this.eventItensPorPagina,
      alteraPagina: this.alteraPagina
    };
    //Dados que serão utilizados para montar a tabela
    const dados = {
      cabecalho: [
        {
          nome: "#",
          tipo: "campo",
          classe: "bg-grey",
          classeCol: "col-red",
          indice: "",
          indexacao: "id"
        },
        {
          nome: "CLIENTE",
          tipo: "campo",
          classe: "bg-red",
          classeCol: "",
          indice: "customer",
          indexacao: "name"
        },
        {
          nome: "LOJA",
          tipo: "campo",
          classe: "bg-red",
          classeCol: "",
          indice: "store",
          indexacao: "name"
        },
        {
          nome: "STATUS PEDIDO",
          tipo: "status",
          classe: "bg-red",
          classeCol: "",
          indice: "",
          indexacao: "state"
        },
        {
          nome: "OPÇÕES",
          tipo: "options",
          classe: "bg-red",
          classeCol: "",
          indice: "",
          indexacao: ""
        }
      ],
      tipo: "pedidos",
      qtdItens: this.state.qtdPedidos,
      itensPorPagina: this.state.itensPorPagina,
      pagina: this.state.pagina
    };

    if(this.state.listagemDePedidos === null) return <div className="spinner"></div>
    if(idPedido) return <DetailsPage idPedido={idPedido} pedido={pedido} closePage={this.closePage} />
    return (
      <div>
        <div className="card">
          
          <div className="row clearfix">
            <div className="col-md-12">
              <div className="col-md-12">
                <h2>
                  <i className="material-icons">format_list_bulleted</i>{" "}
                  Listagem dos Pedidos
                  <ol className="breadcrumb">
                    <li>
                      <a href="/">
                        <i className="material-icons">home</i> Home
                      </a>
                    </li>
                    <li>Listar</li>
                  </ol>
                </h2>
              </div>
            </div>
          </div>
          <hr style={{ margin: 0, padding: 0 }} />
          <div className="body table-responsive">
            <div>
              <div className="row clearfix">
                <div className="col-sm-3">
                  <div>
                    <label>Buscar por:</label>
                    <select
                          style={{
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12)',
                            borderRadius: 2,
                            border: 'none',
                            fontSize: 13,
                            width: 150,
                            height: 38,
                            marginLeft: 20
                          }}
                      ref="filtroBusca"
                      onChange={this.selecionaFiltro.bind(this)}
                    >
                      <option value="customer">Nome</option>
                      <option value="store">Loja</option>
                      <option value="id">ID</option>
                      <option value="description">Status</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <div className="form-line">
                      <input
                        type="text"
                        id="campo-busca"
                        ref="campoBusca"
                        className="form-control"
                        placeholder="Buscar..."
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="form-line">
                      <button
                        className="btn btn-block btn-lg bg-red waves-effect"
                        onClick={() => {
                          this.efetuarBusca(this.state.itensPorPagina, 0);
                        }}
                      >
                        <i className="material-icons">search</i>
                        <span>Buscar</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.filtroSwitch ? (
                <div className="alert alert-warning">
                  Você está buscando por: "{this.refs.campoBusca.value}".
                  <span className="pull-right">
                    <b>
                      <a
                        href="/"
                        onClick={e => {
                          e.preventDefault();
                          this.removerFiltro();
                        }}
                      >
                        Clique aqui
                      </a>{" "}
                      para remover o filtro.
                    </b>
                  </span>
                </div>
              ) : (
                <div />
              )}
              {this.state.listagemDePedidos !== null ? (
                <Tabela
                  conteudo={this.state.listagemDePedidos}
                  dados={dados}
                  metodos={metodosTabela}
                />
              ) : (
                <div className="pqer" style={{ textAlign: "center" }}>
                  <div className="spinner-layer pl-red">
                    <div className="circle-clipper right">
                      <div className="circle" />
                    </div>
                    <div className="circle-clipper left">
                      <div className="circle" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

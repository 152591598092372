import React from "react";

const CustomerCard = (props) => {
  const { name, email, telephone, deliveryAddress, cpf, rules } = props
  return (
    <div className="col-sm-12 col-md-12 col-lg-12">
      <div className="card">
        <div className="body bg-grey">
          <div className="table-resonsive">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="2">
                    <span>
                      <i className="material-icons">info_outline</i> INFORMAÇÕES
                      DO COMPRADOR:
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Nome:</th>
                  <td>{name}</td>
                </tr>
                <tr>
                  <th>E-mail:</th>
                  <td>{email}</td>
                </tr>
                <tr>
                  <th>CPF:</th>
                  <td>{cpf}</td>
                </tr>
                <tr>
                  <th>Telefone:</th>
                  <td>{telephone}</td>
                </tr>
                <tr>
                  <th>Endereço:</th>
                  <td>{deliveryAddress.street}, N°{deliveryAddress.number},{deliveryAddress.more}, {deliveryAddress.neighborhood}, {deliveryAddress.city}, {deliveryAddress.cep}   </td>
                </tr>
                <tr>
                  <th>Em caso de não haver algum produto:</th>
                  <td>
                    {rules.cancela ? <p>Cancelar pedido</p> : null}
                    {rules.parcial ? <p>Enviar parcial</p> : null}
                    {rules.similar ? <p>Enviar similar</p> : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCard;

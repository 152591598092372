import { APIRequest, Endpoints, APIFetch, SOCKET_API } from "../../Endpoints";
import swal from "sweetalert2";
import { ORDER_NOTIFICATION } from "../../enum/Notifications";
import { MARKER_TYPE } from "../../enum/MarkerType";

export const listarPedidos = (changeOrders, pagina, itensPorPagina = 20) => {
  const request = APIRequest(
    Endpoints.getOrders,
    {
      limit: itensPorPagina,
      offset: itensPorPagina * (pagina - 1)
    },
    sessionStorage.apitoken
  );

  APIFetch(request, response => {
    const orders = response.results.map(o => ({
      id: o.id,
      type: MARKER_TYPE.ORDER,
      state: o.state,
      description: o.description,
      hasStore: !!o.store,
      productsCount: o.products,
      // productsCount: o.products.length,
      latitude: 0, //o.delivery_address.latitude,
      longitude: 0, //o.delivery_address.longitude,
      createdTime: o.create_time
    }));

    changeOrders(orders);
  });
};

export const listarLojas = changeStores => {
  const request = APIRequest(
    Endpoints.getStores,
    null,
    sessionStorage.apitoken
  );

  APIFetch(request, response => {
    const stores = response.results.map(s => ({
      id: s.id,
      type: MARKER_TYPE.STORE,
      name: s.name,
      latitude: s.latitude,
      longitude: s.longitude
    }));

    changeStores(stores);
  });
};

const mountNotification = message => {
  const msg = JSON.parse(message.data);
  switch (msg.type) {
    case "new-order":
      return {
        type: ORDER_NOTIFICATION.NEW,
        order: {
          id: msg.order.id,
          state: msg.order.state,
          latitude: msg.order.delivery_address.latitude,
          longitude: msg.order.delivery_address.longitude
        }
      };
  }
};

export const ordersWSConnection = onReceiveOrderMessage => {
  let conexao;
  conexao = new WebSocket(SOCKET_API + "/orders/");

  conexao.onerror = function(error) {
    swal(
      "Atenção!",
      "Ocorreu um problema ao sincronizar os dados com o servidor da SpeedFarma, atualize a página e tente novamente.",
      "warning"
    );
  };

  conexao.onopen = open => console.log("Orders WebSocket opened!");

  //Quando um pedido for alterado/recebido
  conexao.onmessage = message => {
    const notification = mountNotification(message);
    if (notification) {
      onReceiveOrderMessage(notification);
    }
  };
};


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

//TEMPLATE MATERIAL
import jquery from 'jquery';
window.$ = window.jQuery = jquery;
/* CSS */
require('adminbsb-materialdesign/plugins/bootstrap/css/bootstrap.css');
require('adminbsb-materialdesign/plugins/node-waves/waves.min.css');
require('adminbsb-materialdesign/css/style.css');
require('adminbsb-materialdesign/plugins/animate-css/animate.min.css');
require('adminbsb-materialdesign/plugins/bootstrap-select/css/bootstrap-select.css');
require('adminbsb-materialdesign/css/themes/theme-red.min.css');

/* JS */
require('bootstrap');
require('bootstrap-select');
require('jquery-slimscroll');
const Waves = require('adminbsb-materialdesign/plugins/node-waves/waves.min');
window.Waves = Waves;
require('adminbsb-materialdesign/js/admin');

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();

export class Status {
  static Pedidos = {
    "em andamento": {
      classeLabel: "label bg-orange",
      texto: "Em Andamento",
      icone: "fa fa-circle-o-notch fa-spin fa-fw"
    },
    "checkout": {
      classeLabel: "label bg-orange",
      texto: "Checkout",
      icone: "fa fa-circle-o-notch fa-spin fa-fw"
    },
    entregue: {
      classeLabel: "label bg-light-green",
      texto: "Entregue",
      icone: "fa fa-check-circle"
    },
    cancelado: {
      classeLabel: "label bg-red",
      texto: "Cancelado",
      icone: "fa fa-times-circle"
    },
    "aguardando ofertas": {
      classeLabel: "label bg-orange",
      texto: "Aguardando ofertas",
      icone: "fa fa-circle-o-notch fa-spin fa-fw"
    },
    "aguardando pagamento": {
      classeLabel: "label bg-orange",
      texto: "Aguardando pagamento",
      icone: "fa fa-circle-o-notch fa-spin fa-fw"
    }, "A caminho": {
      classeLabel: "label bg-orange",
      texto: "a caminho",
      icone: "fa fa-circle-o-notch fa-spin fa-fw"
    },
    "aguardando remessa": {
      classeLabel: "label bg-orange",
      texto: "Aguardando remessa",
      icone: "fa fa-circle-o-notch fa-spin fa-fw"
    }
  };

  static PedidosModal = {
    "em andamento": {
      classePanel: "header bg-orange",
      texto: "Em Andamento",
      icone: "fa fa-circle-o-notch fa-spin fa-fw"
    },
    entregue: {
      classePanel: "header bg-light-green",
      texto: "Entregue",
      icone: "fa fa-check-circle"
    },
    cancelado: {
      classePanel: "header bg-red",
      texto: "Cancelado",
      icone: "fa fa-times-circle"
    }
  };

  static StatePedidos = {
    agregando: {
      icone: "fa fa-spinner fa-pulse fa-fw",
      style: { color: "#000" }
    },
    ganho: {
      icone: "fa fa-spinner fa-pulse fa-fw",
      style: { color: "#000" }
    },
    "aguardando ofertas": {
      icone: "fa fa-spinner fa-pulse fa-fw",
      style: { color: "#000" }
    },
    "aguardando ofertas accept": {
      icone: "fa fa-check-circle-o",
      style: { color: "#000" }
    },
    "em análise": { icone: "fa fa-check-circle", style: { color: "#000" } },
    completo: { icone: "fa fa-check-circle", style: { color: "#99ff99" } },
    checkout: { icone: "fa fa-certificate", style: { color: "#000" } },
    "Pedido Expirado": {
      icone: "fa fa-hourglass-end",
      style: { color: "#000" }
    },
    "aguardando remessa": { icone: "fa fa-archive", style: { color: "#000" } },
    "preparando remessa": { icone: "fa fa-archive", style: { color: "#000" } },
    "a caminho": { icone: "fa fa-motorcycle", style: { color: "#000" } },
    entregando: { icone: "fa fa-motorcycle", style: { color: "#000" } },
    entregue: { icone: "fa fa-check-circle", style: { color: "#000" } },
    finalizado: { icone: "fa fa-check-circle", style: { color: "#000" } },
  };
}

export const convertStateToPedidosModal = (state) => {
  switch (state) {
    case "aguardando ofertas accept":
    case "aguardando remessa":
    case "preparando remessa":
    case "a caminho":
    case "entregando":
    case "entregue":
    case "finalizado":
      return Status.PedidosModal['entregue'];
    case "Pedido Expirado":
      return Status.PedidosModal['cancelado'];
    case "em andamento":
    case "agregando":
    case "aguardando ofertas":
    default:
      return Status.PedidosModal['em andamento'];

  }
}

import styled from "styled-components";

export const MultiLocationWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 70px;
  left: 30%;
  width: 40%;
`;

export const MultiLocationPaper = styled.div.attrs({
  className: "card"
})`
  width: 100%;
  padding: 8px;
  margin: 0 !important;
`;

export const Divider = styled.div`
  width: 96%;
  border-top: 1px solid grey;
  margin: auto;
  margin-top: 12px;
`;

export const Header = styled.div`
  text-align: center;
`;

const TextButton = styled.button.attrs({
  type: "button",
  className: "btn btn-link waves-effect"
})``;

export const OpenText = styled(TextButton)``;

export const CloseText = styled(TextButton)`
  position: absolute !important;
  right: 4px;
  top: 4px;
`;

export const Content = styled.div`
  overflow-y: auto;
`;

export const List = styled.div`
  width: 100%;
  height: 210px;
`;

import React, { Component } from "react";
import { Error } from "../Errors";
import { Endpoints, APIRequest, APIFetch } from "../Endpoints";
import "sweetalert2/dist/sweetalert2.min.css";
import swal from "sweetalert2";
import Logo from "../images/logo.png";

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: ""
    };
  }

  updateField(event) {
    var key = event.target.id;
    var value = event.target.value;

    this.setState({
      [key]: value
    });
  }

  login = () => {
    let username = this.state.username;
    let password = this.state.password;

    if (username === "" || password === "") {
      //this.setState({showAlert: true});
      swal("Hey!", "Os campos de acesso não podem ficar vazios!", "warning");
    } else {
      var request = APIRequest(Endpoints.login, {
        username: username,
        password: password
      });
      APIFetch(
        request,
        function (result) {
          // Salva o resultado em storage de sessão e depois redireciona para o Painel.
          // TODO: Redirecionar deveria usar o Router.
          sessionStorage.apitoken = result["token"];
          window.location.href = "/";
        },
        error => {
          swal(Error.Login[error["non_field_errors"][0]], "", "error");
        }
      );
    }
  };

  render() {
    return (
      <div
        className="ls-closed"
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', justifyContent: 'center' }}
      >
        <img src={Logo} style={{marginBottom: 40}}/>
        <div className="login-box">
          <div className="card" style={{ width: 448 }}>
            <div className="header">
              <h2>
                Acesse sua conta 
              </h2>
            </div>
            <div className="body">
              <form id="sign_in" method="POST" noValidate="novalidate">
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="material-icons">person</i>
                  </span>
                  <div className="form-line">
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      name="username"
                      placeholder="Usuário"
                      onChange={this.updateField.bind(this)}
                      required="required"
                      aria-required="true"
                    />
                  </div>
                </div>
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="material-icons">lock</i>
                  </span>
                  <div className="form-line">
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      onChange={this.updateField.bind(this)}
                      placeholder="Senha"
                      required="required"
                      aria-required="true"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-8 p-t-5">
                    <input
                      type="checkbox"
                      name="rememberme"
                      id="rememberme"
                      className="filled-in chk-col-pink"
                    />
                  </div>
                  <div className="col-xs-12">
                    <button
                      className="btn btn-block bg-red waves-effect btn-lg"
                      type="button"
                      onClick={() => {
                        this.login();
                      }}
                    >
                      Entrar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
//import ReactDOM from 'react-dom';
import Script from "react-load-script";
import { Aubergine } from "./themes";

export default class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }
  static defaultProps = {
    apiKey: "AIzaSyA1xxcGHwFGzIUcjEHJntFPlY_Fukq1M2s",
    mapCenter: { lat: -23.00967, lng: -43.44173 }
  };

  handleScriptLoad() {
    this.setState({
      scriptLoaded: true,
      loading: false,
      google: window.google
    });
    this.initMap();
    var upd = setInterval(function() {
      window.dispatchEvent(new Event("resize"));
      clearInterval(upd);
    }, 2000);
  }

  initMap = () => {
    window.map = new window.google.maps.Map(document.getElementById("Map"), {
      zoom: 18,
      center: this.props.mapCenter,
      styles: Aubergine
    });

    if ("onLoad" in this.props) {
      this.props.onLoad();
    }
  };

  render() {
    let loader = null;
    return (
      <div id="map-wrapper">
        {loader}
        <div ref="map" id="Map" className="Map" style={{ height: 400 }}>
          <Script
            url={`https://maps.googleapis.com/maps/api/js?key=${
              this.props.apiKey
            }`}
            onLoad={this.handleScriptLoad.bind(this)}
          />
          {this.props.children}
        </div>
      </div>
    );
  }
}

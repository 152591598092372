// Imported Icons
import store from './icons/store-32.png'
import store_pending from './icons/store_pending-32.png'
import store_active from './icons/store_active-32.png'
import client from './icons/client-64.png'
import delivery from './icons/delivery-64.png'


const icons = {
    store: {
        icon: store,
        pending: store_pending,
        active: store_active
    },
    client: {
        icon: client
    },
    delivery: {
        icon: delivery
    }
}

export function mapsAlive() {
    if (!("map" in window) || !("google" in window)) {
        return false
    }
    return true
}

export function addMarker(feature, info) {
    if (!mapsAlive()) { return null }

    let map = window.map
    let google = window.google

    let image = {
        url: icons[feature.type].icon,
        size: new google.maps.Size(32,32),
        origin: new google.maps.Point(0,0),
        anchor: new google.maps.Point(16,16),
        labelOrigin: new google.maps.Point(16,40)
    }

    let marker = new google.maps.Marker({
        position: feature.position,
        icon: image,
        map: map,
        label: ("label" in feature) ? feature.label : null
    })

    // Draggable Marker Config
    if (feature.onDrag) {
        map.panTo(marker.getPosition());
        map.addListener("center_changed", () => {
            clearTimeout(feature.timer)
            marker.setPosition(map.getCenter())

            feature.timer = setTimeout(() => {
                feature.onDrag(marker)
            }, 500)
        })

        feature.unmount = () => {
            google.maps.event.clearListeners(map, 'center_changed');
        }
    }

    // InfoWindow Config
    if (info) {
        let infowindow = new google.maps.InfoWindow({
            content: info
        })

        marker.addListener('click', function() {
            infowindow.open(map, marker)
    
            map.addListener('click', () => {
              infowindow.close()
            })
        })

        // Customizing marker class
        marker.infowindow = infowindow
    }

    // Icon @ Mode Config
    marker.setPending = () => {
        if (marker.status === "active") { return }

        image.url = icons[feature.type].pending || icons[feature.type].icon
        marker.setIcon(image)
        marker.status = "pending"
    }

    marker.setActive = () => {
        image.url = icons[feature.type].active || icons[feature.type].icon
        marker.setIcon(image)
        marker.status = "active"
    }

    marker.setNormal = () => {
        image.url = icons[feature.type].icon
        marker.setIcon(image)
        marker.status = "normal"
    }

    // Set label text
    marker.setText = (text) => {
        let label = {text: text, color: "white"}
        marker.setLabel(label)
    }

    return marker
}

export function infoWindow(content) {
    if (!mapsAlive()) { return null }

    let google = window.google
    
    let infowindow = new google.maps.InfoWindow({
        content: content
    })

    return infowindow
}
 
export function addCircle(origin, radius) {
    if (!mapsAlive()) { return null }

    let map = window.map
    let google = window.google

    let circle = new google.maps.Circle({
        center: origin,
        editable: true,
        draggable: false,
        clickable: false,
        radius: radius,
        map: map
    })

    map.fitBounds(circle.getBounds())
    return circle
}

export function removeCircle(circle) {
    if (!mapsAlive()) { return null }

    let google = window.google

    circle.setMap(null)
    google.maps.event.clearListeners(circle, 'radius_changed')
}
import React from "react";

const OlderOrdersButton = ({ onClick }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <button
        className="btn btn-default waves-effect waves-float"
        style={{ bottom: "50px" }}
        onClick={onClick}
      >
        VER MAIS ANTIGOS
      </button>
    </div>
  );
};

export default OlderOrdersButton;

import React from "react";
import { Timeline } from "../Timeline";

const bodyCardFarmacias = {
  maxHeight: 620,
  height: 620,
  overflowX: "auto"
};

const TimelineCard = ({ order }) => {
  return (
    <div className="card boxPedidoTimeline">
      <div className="header bg-light-blue">
        <h2>
          <i className="fa fa-history" aria-hidden="true" />
          &nbsp; Histórico
          <small>Linha do tempo do pedido</small>
        </h2>
      </div>
      <div className="body" style={bodyCardFarmacias}>
        <Timeline order={order} />
        <div />
      </div>
    </div>
  );
};

export default TimelineCard;

import React from "react";
import get from "lodash/get";
import swal from "sweetalert2";
import orderBy from "lodash/orderBy";
import InputMask from "react-input-mask";
import CurrencyInput from 'react-currency-input-field';
import OrderDetailMap from "../OrderDetailMap";
import { SOCKET_API, APIRequest, APIFetch, Endpoints } from "../../Endpoints";

import StoreCard from "./StoreCard";
import PaymentCard from "./PaymentCard";
import CustomerCard from "./CustomerCard";
import ProductsCard from "./ProductsCard";
import TimelineCard from "./TimelineCard";
import ModalTitle from "./ModalTitle";

class DetailsPage extends React.PureComponent {
  state = {
    pedidoSelecionado: null,
    detalhesPedido: null,
    customersDetail: null,
    isModalOpen: false,
    delivery_address: {},
    store: {},
    changeStore: false,
    newValue: 0,
    status: "",
    listagemDeLojas: null,
    storeSelected: null,
    modalType: "",
    loadingModal: false,
    loadingButton: false,
    productSelected: null,
  };

  componentDidMount() {
    const { idPedido } = this.props;

    const propsWaitMe = {
      effect: "rotation",
      text: "Carregando, por favor aguarde.",
      bg: "rgba(255,255,255,0.90)",
      color: "lightBlue",
    };
    window.jQuery(document).ready(function () {
      window.jQuery(".boxPedidoTimeline").waitMe(propsWaitMe);
    });
    this.setState({ pedidoSelecionado: idPedido });

    var requestStore = APIRequest(
      Endpoints.searchStores,
      { limit: 1000 },
      sessionStorage.apitoken
    );
    APIFetch(requestStore, (response) => {
      if (response.count > 0) {
        let listagem = orderBy(response.results, ["id"], ["desc"]);
        this.setState({ listagemDeLojas: listagem });
      }
    });

    const requestPedido = APIRequest(
      Endpoints.fullOrders,
      { id: idPedido },
      sessionStorage.apitoken
    );

    APIFetch(
      requestPedido,
      (response) => {
        if (response !== null) {
          this.setState({ detalhesPedido: response });
          let reqGetCustomersDetail = APIRequest(
            Endpoints.getClientById,
            { id: response.customer_id },
            sessionStorage.apitoken
          );

          APIFetch(reqGetCustomersDetail, (res) => {
            this.setState({ customersDetail: res });
          });

          this.socketPedido(idPedido, "open");
          let estado = response.state;
          let description = response.state;
          // this.attStatusPedido({ estado: estado, description: description });
          window.jQuery(document).ready(function () {
            window.jQuery("#modalPedido").modal("show");
          });
        }
      },
      (error) => {
        //Erro na execução do script no servidor.
        if (error.fatal !== undefined) {
          swal(
            "Não foi possível carregar os detalhes do pedido.",
            "Ocorreu um erro ao retornar os dados do servidor.",
            "error"
          );
        } else {
          swal(
            "Não foi possível carregar os detalhes do pedido.",
            "Ocorreu um erro ao se conectar com servidor.",
            "error"
          );
        }
      }
    );
  }

  attStatusPedido = (dados) => {
    const { detalhesPedido } = this.state;
    this.setState({
      detalhesPedido: { ...detalhesPedido, ...dados },
    });
  };

  socketPedido = (idPedido, flag) => {
    if (flag === "open") {
      let conexao = new WebSocket(SOCKET_API + `/orders/${idPedido}/`);
      this.setState({ conexaoPedido: conexao });
      window.conexaoPedido = conexao;
      conexao.onopen = (dadosOpen) => {
        conexao.send("update");
      };

      conexao.onmessage = (message) => {
        let msg = JSON.parse(message.data);

        //Caso o pedido esteja expirado, retirar da listagem fora do Modal
        if (msg.type === "order-expired") {
          let lista = JSON.parse(JSON.stringify(this.state.listagemDePedidos));
          lista.math = Math.random();
          lista.map((linha, indice) => {
            if (linha.id === idPedido) {
              lista.splice(indice, 1);
              this.setState({ listagemDePedidos: lista });
            }
          });
          this.attStatusPedido({
            estado: "Pedido Expirado",
            description: "cancelado",
          }); //Chama a função para atualizar o status e descrição do pedido
        } else if (msg.type === "proposal-update") {
          //Buscar pela loja escolhida e colocar um "check" ao lado do nome da loja

          let listagem = JSON.parse(
            JSON.stringify(this.state.listagemDeFarmacias)
          );
          //console.log(listagem);
          this.state.listagemDeFarmacias.map((dados, indice) => {
            if (dados.id === msg.id) {
              listagem[indice].state = msg.state; //Atualiza o Status
            }
          });
          this.setState({ listagemDeFarmacias: listagem });
        } else {
          if ("order" in msg) {
            let estado = msg.order.state;
            let description = msg.order.description;
            //console.log(estado, description);
            this.attStatusPedido({ estado: estado, description: description }); //Chama a função para atualizar o status e descrição do pedido
          } else if ("state" in msg && "description" in msg) {
            let estado = msg.state;
            let description = msg.description;
            this.attStatusPedido({ estado: estado, description: description }); //Chama a função para atualizar o status e descrição do pedido
          }
        }
      };

      conexao.onclose = () => {};
    } else {
      this.setState({
        statusPedido: null,
        listagemDeFarmacias: [],
        detalhesPedido: null,
      });
      this.state.conexaoPedido && this.state.conexaoPedido.close();
    }
  };

  _renderCard = (info, Card) => {
    if (info) {
      return <Card {...info} />;
    }
  };

  callModal = (type) => {
    this.setState({ modalType: type });

    window.jQuery("#modalChangeStore").modal("show");
  };

  cancelOrder = () => {
    var request = APIRequest(
      Endpoints.cancelOrder,
      {
        id: this.state.detalhesPedido.id,
      },
      sessionStorage.apitoken
    );
    APIFetch(
      request,
      function (result) {
        //console.log(result);
      },
      (error) => {
        //console.log(error);
      }
    );
  };

  selecionaStore = (event) => {
    this.setState({ storeSelected: event.target.value });
  };

  sendNewPrice = async () => {
    const { newValue, detalhesPedido } = this.state;
    this.setState({ loadingModal: true });
    let products = [];
    const totalProducts = detalhesPedido.products.length;
    const totalFloatByProduct =
      newValue.replace(/[^0-9.-]+/g, "") / totalProducts;

    await detalhesPedido.products.map((item) => {
      const fragmentPrice = totalFloatByProduct / item.quantity;

      products.push({ id: item.id, price: fragmentPrice });
    });
    // console.log('products >>>>>', products);
    var request = APIRequest(
      Endpoints.processOrder,
      {
        id: detalhesPedido.id,
        products,
      },
      sessionStorage.apitoken
    );
    // console.log('request >>>>>', request);
    APIFetch(
      request,
      function (result) {
        window.location.reload();
        //console.log(result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  changeStore = () => {
    const { storeSelected } = this.state;
    this.setState({ loadingModal: true });

    var request = APIRequest(
      Endpoints.changeStore,
      {
        id: this.state.detalhesPedido.id,
        store: storeSelected,
      },
      sessionStorage.apitoken
    );
    APIFetch(
      request,
      (result) => {
        window.location.reload();
        this.setState({ changeStore: true });
      },
      (error) => {
        console.log(error);
      }
    );
  };

  nextProcess = () => {
    const { detalhesPedido } = this.state;
    this.setState({ loadingButton: true });
    var request = APIRequest(
      Endpoints.processOrder,
      {
        id: detalhesPedido.id,
      },
      sessionStorage.apitoken
    );
    APIFetch(
      request,
      (result) => {
        this.setState({ loadingButton: false });
        //console.log(result);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  getButtons = () => {
    const { detalhesPedido, loadingButton } = this.state;

    if (detalhesPedido.estado === "cancelado") return null;

    if (
      detalhesPedido.state === "checkout" ||
      detalhesPedido.state === "aguardando ofertas"
    ) {
      return (
        <div style={{ width: "100%" }}>
          <button
            type="button"
            className="btn btn-lg bg-red waves-effect"
            data-dismiss="modal"
            onClick={() => this.callModal("changeStore")}
            style={{ width: "100%", marginBottom: 10 }}
          >
            ADMINISTRAR PEDIDO
          </button>
          {detalhesPedido.state !== "aguardando ofertas" && (
            <button
              type="button"
              className="btn btn-lg bg-red waves-effect"
              data-dismiss="modal"
              onClick={() => this.callModal("newValue")}
              style={{ width: "100%", marginBottom: 10 }}
            >
              NOVO VALOR DA NOTA
            </button>
          )}
          <button
            type="button"
            className="btn btn-lg bg-grey waves-effect"
            data-dismiss="modal"
            style={{ width: "100%", marginBottom: 10 }}
            onClick={this.cancelOrder}
          >
            CANCELAR PEDIDO
          </button>
        </div>
      );
    }

    if (
      detalhesPedido.state !== "entregue" ||
      detalhesPedido.estado !== "entregue"
    ) {
      return (
        <div>
          <button
            type="button"
            className="btn btn-lg bg-red waves-effect"
            data-dismiss="modal"
            onClick={() => this.nextProcess()}
            style={{
              width: "100%",
              marginBottom: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loadingButton ? (
              <div className="spinnerButton"></div>
            ) : (
              "Progresso"
            )}
          </button>
          <button
            type="button"
            className="btn btn-lg bg-grey waves-effect"
            data-dismiss="modal"
            style={{ width: "100%", marginBottom: 10 }}
            onClick={this.cancelOrder}
          >
            CANCELAR PEDIDO
          </button>
        </div>
      );
    }
    return null;
  };
  render() {
    const {
      detalhesPedido,
      customersDetail,
      isModalOpen,
      listagemDeLojas,
      changeStore,
      modalType,
      loadingModal,
      productSelected,
    } = this.state;
    
    if (!detalhesPedido || !customersDetail)
      return <div className="spinner"></div>;

    const { name, email, telephone, cpf } = customersDetail;
    const { delivery_address, rules } = detalhesPedido;

    const titleInfo = {
      id: get(detalhesPedido, "id"),
      description: get(detalhesPedido, "estado"),
      storeName: get(detalhesPedido, "store.name"),
      customerName: get(detalhesPedido, "customer.name"),
    };
    const storeInfo = {
      ...this.props.pedido.store,
      deliveryAddress:delivery_address
    };
    const paymentInfo = {
      payments: detalhesPedido.payments,
      delivery_fee: detalhesPedido.delivery_fee,
      discounted_price: detalhesPedido.discounted_price,
      rules: JSON.parse(rules||{})
    };
    //console.log(this.props.pedido.store);
    const customerInfo = get(detalhesPedido, "customer");
    const productsInfo = {
      products: get(detalhesPedido, "products"),
      final_total: get(detalhesPedido, "final_total"),
      total: get(detalhesPedido, "total"),
      delivery_fee: detalhesPedido.delivery_fee,
    };
    //console.log('newValue', this.state.newValue);
    return (
      <div style={{ backgroundColor: "white" }}>
        <div className="header">
          <h3>
            <button
              className="btn btn-link"
              onClick={() => {
                this.props.closePage();
              }}
              style={{ marginRight: 10 }}
            >
              <i className="material-icons">keyboard_backspace</i>
            </button>
            &nbsp;<b>Detalhe do Pedido:</b>
          </h3>
        </div>
        <div className="">
          <div className="row clearfix">
            <ModalTitle {...titleInfo} />
          </div>
          <div className="row clearfix">
            <div className="col-sm-4 col-md-4 col-lg-4" style={{ padding: 0 }}>
              <StoreCard {...storeInfo} />
              <PaymentCard {...paymentInfo} />
              {customersDetail && (
                <CustomerCard
                  name={name}
                  cpf={cpf}
                  email={email}
                  telephone={telephone}
                  deliveryAddress={delivery_address}
                  rules={JSON.parse(rules||{})}
                />
              )}
              <ProductsCard
                {...productsInfo}
                onClick={(item) => {
                  this.callModal("modalProduct");
                  this.setState({ productSelected: item });
                }}
              />
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8" style={{ padding: 0 }}>
              <div className="col-sm-6 col-md-6 col-lg-6">
                {detalhesPedido && this.props.pedido.store && (
                  <OrderDetailMap
                    customer={
                      get(detalhesPedido, "delivery_address.latitude") &&
                      get(detalhesPedido, "delivery_address")
                    }
                    store={
                      get(detalhesPedido, "store.latitude") && {
                        latitude: this.props.pedido.store.latitude,
                        longitude: this.props.pedido.store.longitude,
                      }
                    }
                  />
                )}
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                {detalhesPedido && <TimelineCard order={detalhesPedido.id} />}
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  {this.getButtons()}
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="modalChangeStore" role="dialog">
            <div
              className="modal-dialog"
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loadingModal ? (
                <div className="spinner"></div>
              ) : modalType === "changeStore" ? (
                <div
                  className="modal-content"
                  style={{
                    width: 400,
                    height: 200,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <div className="modal-header">
                    <h4 className="modal-title" style={{ display: "flex" }}>
                      <i className="material-icons" style={{ marginRight: 10 }}>
                        store
                      </i>
                      Alterar Loja:
                    </h4>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <select
                      style={{
                        boxShadow:
                          "0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12)",
                        borderRadius: 2,
                        border: "none",
                        fontSize: 13,
                        width: "80%",
                        height: 38,
                      }}
                      ref="filtroBusca"
                      onChange={this.selecionaStore}
                    >
                      {listagemDeLojas.map((store) => (
                        <option value={store.id}>{store.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="modal-footer">
                    <button
                      className="btn btn-secondary waves-effect btn-lg"
                      data-dismiss="modal"
                      style={{ marginRight: 20 }}
                      onClick={() =>
                        window.jQuery("#modalChangeStore").modal("hide")
                      }
                    >
                      Cancelar
                    </button>
                    <button
                      className="btn btn-success waves-effect btn-lg"
                      onClick={() => {
                        this.changeStore();
                      }}
                    >
                      Trocar
                    </button>
                  </div>
                </div>
              ) : modalType === "modalProduct" ? (
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="col-sm-12 col-lg-12 col-md-12">
                      <div style={{ width: 400, height: 400 }}>
                        <img
                          style={{ width: 200, height: "auto" }}
                          src={productSelected.image}
                        />
                        <p style={{ fontWeight: "bold" }}>
                          {productSelected.name}
                        </p>
                        <p>{productSelected.prescription}</p>
                        <p>{productSelected.scientific_name}</p>
                        <p>
                          Valor:{" "}
                          {productSelected.price.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      className="btn btn-secondary waves-effect btn-lg"
                      data-dismiss="modal"
                      style={{ marginRight: 20 }}
                      onClick={() =>
                        window.jQuery("#modalChangeStore").modal("hide")
                      }
                    >
                      Fechar
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  className="modal-content"
                  style={{
                    width: 400,
                    height: 200,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <div className="modal-header">
                    <h4 className="modal-title" style={{ display: "flex" }}>
                      <i className="material-icons" style={{ marginRight: 10 }}>
                        store
                      </i>
                      Coloque o novo Valor da compra:
                    </h4>
                  </div>
                  {/* <InputMask
                    type="text"
                    mask="R$ 99.99"
                    id="newValue"
                    maskPlaceholder={null}
                    ref="newValue"
                    className="form-control"
                    placeholder="Novo Valor"
                    onChange={(e) =>
                      this.setState({
                        newValue: e.target.value.replace(/[^0-9.]/g, ""),
                      })
                    }
                  /> */}
                  <CurrencyInput
                    id="newValue"
                    name="newValue"
                    ref="newValue"
                    prefix="R$ "
                    className="form-control"
                    placeholder="Valor total cupom fiscal"
                    // defaultValue={1000}
                    decimalsLimit={2}
                    onValueChange={(value, name) => this.setState({
                      newValue: `${value}`
                    })}
                  />;
                  <div className="modal-footer">
                    <button
                      className="btn btn-secondary waves-effect btn-lg"
                      data-dismiss="modal"
                      style={{ marginRight: 20 }}
                      onClick={() =>
                        window.jQuery("#modalChangeStore").modal("hide")
                      }
                    >
                      Cancelar
                    </button>
                    <button
                      className="btn btn-success waves-effect btn-lg"
                      onClick={() => {
                        this.sendNewPrice();
                      }}
                    >
                      Registrar
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailsPage;

import React, { Component } from "react";
import { Home } from "./containers/Home";
import { ListaClientes } from "./clientes/ListaClientes";
import { ListaLojas } from "./lojas/ListaLojas";
import { Login } from "./pages/Login";
import { ListaPedidos } from "./pedidos/ListaPedidos";
import imgPerfil from "./images/imagem-perfil.jpeg";
import { Endpoints, APIRequest, APIFetch } from "./Endpoints";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      escolhaMenu: "home",
      escolhaSubMenu: "",
      conteudo: null,
      infoPerfil: {
        username: "",
        email: "",
      },
    };
  }

  getInfoPerfil = () => {
    let request = APIRequest(Endpoints.userMe, {}, sessionStorage.apitoken);
    APIFetch(request, (perfil) => {
      this.setState({ infoPerfil: perfil });
    });
  };

  selecionaItemMenu = (itemMenu) => {
    switch (itemMenu) {
      case "ListaClientes":
        return <ListaClientes />;
      case "Home":
        return <ListaPedidos />;
      case "ListaLojas":
        return <ListaLojas />;
      default:
        return <ListaPedidos />;
    }
  };

  logout = () => {
    sessionStorage.removeItem("apitoken");
    window.location.href = "/";
  };

  componentDidMount() {
    if (
      sessionStorage.apitoken !== undefined &&
      sessionStorage.apitoken !== null &&
      sessionStorage.apitoken !== ""
    ) {
      let retorno = this.selecionaItemMenu("home");
      this.getInfoPerfil();
      this.setState({ conteudo: retorno });
    }
  }

  render() {
    const renderBoard =
      sessionStorage.apitoken !== undefined &&
      sessionStorage.apitoken !== null &&
      sessionStorage.apitoken !== "";

    return renderBoard ? (
      <div className="theme-red" style={{ height: "auto" }}>
        <div className="navbar">
          <div className="container-fluid">
            <div className="navbar-header">
              <a href="javascript:void(0);" class="bars"></a>
              <a className="navbar-brand" href="/">
                FARMAZON - ADMIN BOARD
              </a>
            </div>
          </div>
        </div>
        <section>
          <aside className="sidebar">
            <div className="user-info">
              <div className="image">
                <img src={imgPerfil} width="48" height="48" alt="Usuário" />
              </div>

              <div className="info-container">
                <div className="name">
                  {this.state.infoPerfil.username.toUpperCase()}
                </div>
                <div className="email">{this.state.infoPerfil.email}</div>

                <div className="btn-group user-helper-dropdown">
                  <i
                    className="material-icons"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    keyboard_arrow_down
                  </i>
                  <ul className="dropdown-menu pull-right">
                    {/* <li><a href="/" className=" waves-effect waves-block" onClick={(e) => { e.preventDefault(); }}><i className="material-icons">person</i>Perfil</a></li>
                                    <li role="seperator" className="divider"></li> */}
                    <li onClick={this.logout.bind(this)}>
                      <a
                        href="/"
                        className=" waves-effect waves-block"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <i className="material-icons">input</i>Sair
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="menu">
              <div className="slimScrollDiv">
                <ul className="list">
                  <li className="header">Menu Principal</li>

                  <li
                    onClick={() => {
                      let retorno = this.selecionaItemMenu("Home");
                      this.setState({ conteudo: retorno });
                    }}
                  >
                    <a
                      href="/"
                      className="waves-effect waves-block"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i className="material-icons">home</i>
                      <span>Home</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="menu-toggle waves-effect waves-block"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i className="material-icons">store_mall_directory</i>
                      <span>Lojas</span>
                    </a>
                    <ul className="ml-menu">
                      <li
                        onClick={() => {
                          let retorno = this.selecionaItemMenu("ListaLojas");
                          this.setState({ conteudo: retorno });
                        }}
                      >
                        <a
                          href="/"
                          className="waves-effect waves-block"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <span>Listagem de Lojas</span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a
                      href="/"
                      className="menu-toggle waves-effect waves-block"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i className="material-icons">person</i>
                      <span>Clientes</span>
                    </a>
                    <ul className="ml-menu">
                      <li
                        onClick={() => {
                          let retorno = this.selecionaItemMenu("ListaClientes");
                          this.setState({ conteudo: retorno });
                        }}
                      >
                        <a
                          href="/"
                          className="waves-effect waves-block"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <span>Listagem de Clientes</span>
                        </a>
                      </li>
                    </ul>
                  </li>

                  {/*<li>
                                    <a href="javascript:void(0);" className="menu-toggle waves-effect waves-block">
                                        <i class="material-icons">motorcycle</i>
                                        <span>Motoboys</span>
                                    </a>
                                    <ul className="ml-menu">
                                        <li><a href="javascript:void(0);" className="waves-effect waves-block"><span>Listagem de Motoboys</span></a></li>
                                    </ul>
                                </li>*/}
                </ul>
              </div>
            </div>
          </aside>
        </section>

        <section className="content">
          <div className="container-fluid">{this.state.conteudo}</div>
        </section>
      </div>
    ) : (
      <Login />
    );
  }
}

export default App;

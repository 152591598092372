import React, { Component } from "react";
import { Endpoints, APIRequest, APIFetch } from "../Endpoints";
import { DataBusca } from "../DataBusca";
import MultistageForm, { Form } from "../Form";
import { CEP, Address, StoreDetails } from "../Fields";
import { Modal } from "../Modal";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";
import Map from "../google-maps/Map";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import * as maptools from "../google-maps/Maptools";
import orderBy from "lodash/orderBy";
import jquery from "jquery";
import { Tabela } from "../Tabela";
import store from "../google-maps/icons/store-32.png";
import { Aubergine } from "../google-maps/themes";
window.$ = window.jQuery = jquery;
require("adminbsb-materialdesign/plugins/jquery-inputmask/jquery.inputmask.bundle");

class PreciseLocation extends Component {
  // TODO: Quando componente fechar, não deveria ser mais possível mover o MARKER.
  componentWillUnmount() {
    //this.props.data.feature.unmount()
  }

  processCep() {
    // TODO: Essa chamada demora um pouco, deveria haver uma
    // tela de loading enquanto o dado não voltou.
    var cep = this.state.cep;
    if (cep.length !== 8) {
      return;
    }

    var request = APIRequest(Endpoints.cep, { cep: cep });
    var form = this;
    APIFetch(request, function(result) {
      form.setState({
        locale: result.logradouro,
        complement: result.complemento,
        region: result.bairro,
        city: result.localidade,
        state: result.uf,
        country: "Brasil"
      });
    });
  }

  render() {
    return (
      <div>
        <Map />
      </div>
    );
  }
}

/* StoreForm + StoreForm Child Components */
class StoreForm extends Form {
  constructor(props) {
    super(props);

    // Data processing handlers
    this.processCep = this.processCep.bind(this);
    this.postAddress = this.postAddress.bind(this);

    // Custom data updaters
    this.updateLocation = this.updateLocation.bind(this);
    this.state = { country: "Brasil" };
  }

  // Callback to MultistageForm
  next(index) {
    // index is the index of the stage who generated this callback.
    if (index === 0) {
      // CEP
      this.processCep();
    } else if (index === 1) {
      // Address
      this.postAddress();
    }
  }

  updateLocation(marker) {
    var latitude = marker.getPosition().lat();
    var longitude = marker.getPosition().lng();

    this.setState({
      latitude: latitude,
      longitude: longitude
    });
  }

  // Data Processing Callbacks
  processCep() {
    // TODO: Essa chamada demora um pouco, deveria haver uma
    // tela de loading enquanto o dado não voltou.
    if (this.state.cep === undefined) {
      return;
    } else {
      var cep = this.state.cep;
      if (cep.length !== 8) {
        return;
      }
      var request = APIRequest(Endpoints.cep, { cep: cep });
      var form = this;
      APIFetch(request, function(result) {
        form.setState({
          locale: result.logradouro,
          complement: result.complemento,
          region: result.bairro,
          city: result.localidade,
          state: result.uf,
          country: "Brasil"
        });
      });
    }
  }

  postAddress() {
    // Campos do endereço
    var locale = this.state.locale;
    var number = this.state.number;
    var city = this.state.city;
    var state = this.state.state;

    // FIXME: E se o sistema funcionar para outros países?
    var country = "Brasil";

    var address = `${locale} ${number}, ${city}, ${state}, ${country}`;

    // Agora, vamos configurar o request e enviar o POST usando o fetchAPI.
    var request = APIRequest(
      Endpoints.geolocate,
      { address: address },
      sessionStorage.apitoken
    );
    var form = this;
    APIFetch(request, function(result) {
      let feature = {
        position: { lat: result.latitude, lng: result.longitude },
        type: "store",
        onDrag: form.updateLocation
      };

      let marker = maptools.addMarker(feature);

      form.setState({
        address: `${locale} ${number}`,
        longitude: result.longitude,
        latitude: result.latitude,
        feature: feature,
        marker: marker
      });
    });
  }

  submit = () => {
    var params = {
      name: this.state.name,
      country: this.state.country,
      state: this.state.state,
      city: this.state.city,
      address: `${this.state.locale} ${this.state.number}`,
      cep: this.state.cep,
      latitude: this.state.marker.position.lat(),
      longitude: this.state.marker.position.lng()
    };
    var requestCadLoja = APIRequest(
      Endpoints.createStore,
      params,
      sessionStorage.apitoken
    );
    APIFetch(requestCadLoja, response => {
      if (response.url !== undefined) {
        let paramsMensagem = {
          showAlert: true,
          tituloAlert: "Loja cadastrada com sucesso!",
          typeAlert: "success",
          mensagemAlert:
            "A loja foi cadastrada com sucesso, para conferir os dados do mesmo, confira na lista abaixo."
        };
        this.showAlert(paramsMensagem);
      } else {
        let paramsMensagem = {
          showAlert: true,
          tituloAlert: "Oops..",
          typeAlert: "error",
          mensagemAlert: "Tivemos um problema ao cadastrar a loja."
        };
        this.showAlert(paramsMensagem);
      }
    });
    //super.submit()
  };

  showAlert = params => {
    this.props.showAlert(params);
  };

  render() {
    const defaultProps = { data: this.state, onChange: this.updateField };
    const formParameters = {
      next: this.next,
      previous: this.previous,
      submit: this.submit
    };

    return (
      <MultistageForm {...formParameters}>
        <CEP name="CEP" {...defaultProps} />
        <Address name="Endereço" {...defaultProps} />
        <PreciseLocation
          name="Localização Precisa"
          data={this.state}
          onChange={this.updateLocation}
        />
        <StoreDetails name="Detalhes da Farmácia" {...defaultProps} />
      </MultistageForm>
    );
  }
}

//Componente do Google Maps utilizado para mostrar no mapa a localização das lojas
const MyMapComponent = withScriptjs(
  withGoogleMap(props => {
    let ObjCenter = { lat: props.data.lat, lng: props.data.lng };
    return (
      <GoogleMap
        defaultZoom={16}
        defaultCenter={ObjCenter}
        defaultOptions={{ styles: Aubergine }}
      >
        {props.data.isMarkerShown && (
          <Marker
            position={{ lat: props.data.lat, lng: props.data.lng }}
            defaultIcon={store}
          />
        )}
      </GoogleMap>
    );
  })
);

export class ListaLojas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itensPorPagina: 5,
      pagina: 0,
      qtdLojas: 0,
      listagemDeLojas: null,
      filtroSelecionado: "name",
      showAlert: false,
      tituloAlert: "",
      typeAlert: "info",
      mensagemAlert: "",
      status: null,
      order: sessionStorage.order,
      storeForm: null,
      modal: null,
      mapa: null,
      mapaToggle: false,
      mapaEndereco: "",
      metodoListagem: "listar",
      filtroSwitch: false
    };

    this.listarLojas(this.state.itensPorPagina, this.state.pagina);
    window.jQuery(document).ready(function() {
      window.jQuery("body").tooltip({ selector: "[data-toggle=tooltip]" });
      window.jQuery(":input").inputmask();
    });
  }

  //Método responsável pelo evento de seleção do filtro
  selecionaFiltro = event => {
    this.setState({ filtroSelecionado: event.target.value });
  };

  //Método responsável por trazer a listagem de lojas
  listarLojas = (itensPorPagina, pagina) => {
    var request = APIRequest(
      Endpoints.searchStores,
      { limit: itensPorPagina, offset: itensPorPagina * pagina },
      sessionStorage.apitoken
    );
    APIFetch(request, response => {
      if (response.count > 0) {
        let listagem = orderBy(response.results, ["id"], ["desc"]);
        this.setState({ qtdLojas: response.count, listagemDeLojas: listagem });
      }
    });
  };

  //Remove o filtro de busca
  removerFiltro = () => {
    this.refs.campoBusca.value = "";
    this.setState({ filtroSwitch: false, pagina: 0, metodoListagem: "listar" });
    this.listarLojas(this.state.itensPorPagina, 0);
  };

  //Método responsável por efetuar uma busca
  efetuarBusca = (itensPorPagina, pagina) => {
    let filtro = this.refs.filtroBusca.value;

    let request;
    if (filtro === "create_time") {
      let campoDe = this.refs.campoDe.refs.campoDe.value;
      let campoAte = this.refs.campoAte.refs.campoAte.value;
      request = APIRequest(
        Endpoints.searchStores,
        {
          created_before: campoDe,
          created_after: campoAte,
          limit: itensPorPagina,
          offset: itensPorPagina * pagina
        },
        sessionStorage.apitoken
      );
    } else {
      request = APIRequest(
        Endpoints.searchStores,
        {
          [filtro]: this.refs.campoBusca.value,
          limit: itensPorPagina,
          offset: itensPorPagina * pagina
        },
        sessionStorage.apitoken
      );
    }
    APIFetch(request, response => {
      if (response.count > 0) {
        let listagem = orderBy(response.results, ["id"], ["desc"]);
        this.setState({
          qtdLojas: response.count,
          listagemDeLojas: listagem,
          metodoListagem: "busca",
          filtroSwitch: true
        });
      }
    });
  };

  eventCamposCad = event => {
    let key = event.target.id;
    let value = event.target.value;

    this.setState({ [key]: value });
  };

  //Mostra o dialog de alerta
  mostraAlertaCad = params => {
    this.setState(params);
  };

  //Chama o Modal para Informações, Edição ou Mapa
  callModal = params => {
    this.setState({ modal: null });
    const conteudoEstrutura = params;
    this.setState({
      modal: <Modal key={params.data.id} params={conteudoEstrutura} />
    });
    window.jQuery("#modalAdmin").modal("show");
  };

  //Chama o Mapa
  verMapa = props => {
    this.setState({
      mapaToggle: true,
      mapaEndereco: props.address,
      mapa: (
        <MyMapComponent
          data={{ isMarkerShown: true, lat: props.lat, lng: props.lng }}
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBYSKy88iSIBmP5zBnwgiLhwvBDBAA3Ptw&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      )
    });
  };

  modalCadadstro = () => {
    this.setState({
      storeForm: (
        <StoreForm
          action={Endpoints.createStore}
          showAlert={this.mostraAlertaCad}
          callBack={this.modalCadadstro}
        />
      )
    });
    window.jQuery(document).ready(function() {
      window.jQuery("#modalCadLojas").modal("show");
    });
  };

  //Atualiza a quantidade de itens por página
  eventItensPorPagina = event => {
    this.setState({ itensPorPagina: event.target.value });
    if (this.state.metodoListagem === "busca") {
      this.efetuarBusca(event.target.value, this.state.pagina);
    } else {
      this.listarLojas(event.target.value, this.state.pagina);
    }
  };

  //Atualiza a página atual
  alteraPagina = ({selected})  => {
    this.setState({ pagina: selected });
    if (this.state.metodoListagem === "busca") {
      this.efetuarBusca(this.state.itensPorPagina, selected);
    } else {
      this.listarLojas(this.state.itensPorPagina, selected);
    }
  };

  render() {
    //Dados que serão utilizados para montar a tabela
    const dados = {
      cabecalho: [
        {
          nome: "#",
          tipo: "campo",
          classe: "bg-grey",
          classeCol: "col-red",
          indice: "",
          indexacao: "id"
        },
        {
          nome: "NOME",
          tipo: "campo",
          classe: "bg-red",
          classeCol: "",
          indice: "",
          indexacao: "name"
        },
        {
          nome: "ENDEREÇO",
          tipo: "campo",
          classe: "bg-red",
          classeCol: "",
          indice: "",
          indexacao: "address"
        },
        {
          nome: "CEP",
          tipo: "campo",
          classe: "bg-red",
          classeCol: "",
          indice: "",
          indexacao: "cep"
        },
        {
          nome: "CIDADE",
          tipo: "campo",
          classe: "bg-red",
          classeCol: "",
          indice: "",
          indexacao: "city"
        },
        {
          nome: "ESTADO",
          tipo: "campo",
          classe: "bg-red",
          classeCol: "",
          indice: "",
          indexacao: "state"
        },
        {
          nome: "OPÇÕES",
          tipo: "options",
          classe: "bg-red",
          classeCol: "",
          indice: "",
          indexacao: ""
        }
      ],
      tipo: "lojas",
      qtdItens: this.state.qtdLojas,
      itensPorPagina: this.state.itensPorPagina,
      pagina: this.state.pagina
    };
    let metodosTabela = {
      callModal: this.callModal,
      eventItensPorPagina: this.eventItensPorPagina,
      alteraPagina: this.alteraPagina,
      verMapa: this.verMapa
    };

    return (
      <div>
        <div className="card">
          {this.state.mapaToggle ? (
            <div className="header">
              <h2>
                <button
                  className="btn btn-default btn-circle waves-effect waves-circle waves-float"
                  onClick={() => {
                    this.setState({ mapaToggle: false, mapa: null });
                    window.jQuery(document).ready(function() {
                      window.jQuery(".selectpicker").selectpicker({
                        style: "btn-default",
                        size: 4
                      });
                    });
                  }}
                  title="Voltar"
                  data-toggle="tooltip"
                >
                  <i className="material-icons">keyboard_backspace</i>
                </button>
                &nbsp;<b>Ver no Mapa:</b> {this.state.mapaEndereco}
              </h2>
            </div>
          ) : (
            <div>
              <div className="row clearfix">
                <div className="col-md-12">
                  <div className="col-md-12">
                    <h2>
                      <i className="material-icons">format_list_bulleted</i>{" "}
                      Listagem de Lojas
                      <ol className="breadcrumb">
                        <li>
                          <a href="/">
                            <i className="material-icons">home</i> Home
                          </a>
                        </li>
                        <li>Lojas</li>
                        <li>Listar</li>
                      </ol>
                    </h2>
                  </div>
                </div>
              </div>
              <hr style={{ margin: 0, padding: 0 }} />
            </div>
          )}

          <div className="body">
            {this.state.mapaToggle ? (
              this.state.mapa
            ) : (
              <div>
                <div className="row clearfix">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>Buscar por:</label>
                      <select
              style={{
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12)',
                borderRadius: 2,
                border: 'none',
                fontSize: 13,
                width: 150,
                height: 38,
                marginLeft: 20
              }}
                        ref="filtroBusca"
                        onChange={this.selecionaFiltro.bind(this)}
                      >
                        <option value="name">Nome</option>
                        <option value="id">ID</option>
                        <option value="address">Endereço</option>
                        <option value="city">Cidade</option>
                        <option value="create_time">Data de Cadastro</option>
                        <option value="state">Estado</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    {this.state.filtroSelecionado !== "create_time" ? (
                      <div>
                        <div className="form-group">
                          <div className="form-line">
                            <input
                              type="text"
                              id="campoBusca"
                              ref="campoBusca"
                              className="form-control"
                              placeholder="Buscar..."
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="form-line">
                              <DataBusca campo="de" ref="campoDe" />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="form-line">
                              <DataBusca campo="ate" ref="campoAte" />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <div className="form-line">
                        <button
                          className="btn btn-block btn-lg bg-red waves-effect"
                          onClick={() => {
                            this.efetuarBusca();
                          }}
                        >
                          <i className="material-icons">search</i>
                          <span>Buscar</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.filtroSwitch ? (
                  <div className="alert alert-warning">
                    Você está buscando por: "{this.refs.campoBusca.value}".
                    <span className="pull-right">
                      <b>
                        <a
                          href="/"
                          onClick={e => {
                            e.preventDefault();
                            this.removerFiltro();
                          }}
                        >
                          Clique aqui
                        </a>{" "}
                        para remover o filtro.
                      </b>
                    </span>
                  </div>
                ) : (
                  <div />
                )}
                <Tabela
                  conteudo={this.state.listagemDeLojas}
                  dados={dados}
                  metodos={metodosTabela}
                />
              </div>
            )}
            {this.state.modal}
            {/* MODAL CADASTRO DE CLIENTES */}
            <div className="modal fade" id="modalCadLojas" role="dialog">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                      <i className="material-icons">add</i> Cadastro de Loja
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="row clearfix">
                      {this.state.storeForm}
                      {/*<StoreForm action={Endpoints.createStore} showAlert={this.mostraAlertaCad}/>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="fixed-action-btn">
              <button
                type="button"
                className="btn btn-danger btn-circle-lg waves-effect waves-circle waves-float"
                data-toggle="tooltip"
                title="Atualizar Lista"
                style={botaoRefresh}
                onClick={() =>
                  this.listarLojas(this.state.itensPorPagina, this.state.pagina)
                }
              >
                <i className="material-icons">refresh</i>
              </button>
              <button
                type="button"
                className="btn btn-danger btn-circle-lg waves-effect waves-circle waves-float"
                data-toggle="tooltip"
                title="Cadastrar Loja"
                onClick={this.modalCadadstro.bind(this)}
                style={botaoMais}
              >
                <i className="material-icons">add</i>
              </button>
            </div>
          </div>
        </div>
        <SweetAlert
          show={this.state.showAlert}
          title={this.state.tituloAlert}
          type={this.state.typeAlert}
          text={this.state.mensagemAlert}
          onConfirm={() => {
            this.setState({ showAlert: false, storeForm: null });
            window.jQuery("#modalCadLojas").modal("hide");
            this.listarLojas();
          }}
        />
      </div>
    );
  }
}

const botaoMais = {
  position: "fixed",
  right: 23,
  bottom: 23,
  marginBottom: 0,
  zIndex: 997
};

const botaoRefresh = {
  position: "fixed",
  right: 23,
  bottom: 23,
  marginBottom: 60,
  zIndex: 997
};

import React from "react";

const boxInfoPedido = {
  height: 233,
  maxHeight: 233,
  overflowY: "auto",
};

const ProductsCard = (props) => {
  const { products, final_total, delivery_fee, total, onClick } = props;
  return (
    <div className="col-sm-12 col-md-12 col-lg-12">
      <div className="card">
        <div className="body bg-grey" style={boxInfoPedido}>
          <div className="table-resonsive">
            <table className="table">
              <thead>
                <tr>
                  <th colSpan="3">
                    <i className="material-icons">shopping_cart</i> PRODUTOS:
                  </th>
                </tr>
              </thead>
              <tbody>
                {products.map((produto, indice) => {
                  console.log(produto);
                  const isPrescriptionNeeds = produto.prescription.indexOf('Sim') > -1;
                  return (
                    <tr
                      style={{ cursor: "pointer" }}
                      key={indice}
                      onClick={() => onClick(produto)}
                    >
                      {isPrescriptionNeeds ? <td>{'⚠️'}</td> : null}
                      <td>{produto.name}</td>
                      <td>{produto.quantity}</td>
                      <td style={{ textAlign: "right" }}>
                        R$ {produto.price * produto.quantity}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <th style={{ textAlign: "left" }} colSpan="2">
                    Valor total:
                  </th>
                  <th style={{ textAlign: "right" }}>
                    R$ {final_total !== null ? final_total : (total-delivery_fee)}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsCard;

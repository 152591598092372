export class ModalLojas {
    static conteudoEstrutura = {
        'fields': [
            {'name': 'ID', 'type': 'hidden', 'key': 'id'},
            {'name': 'Nome', 'type': 'text', 'key': 'name'},
            {'name': 'CEP', 'type': 'text', 'key': 'cep'},
            {'name': 'Endereço', 'type': 'text', 'key': 'address'}
        ]
    }
}

export class ModalClientes {
    static conteudoEstrutura = {
        'fields' : [
            {'name': 'ID', 'type': 'hidden', 'key': 'id'},
            {'name': 'Nome', 'type': 'text', 'key': 'name'},
            {'name': 'E-mail', 'type': 'text', 'key': 'email'},
            {'name': 'Telefone', 'type': 'text', 'key': 'telephone'}
        ]
    }
}
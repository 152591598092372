/*global google*/

import React, { Component, Fragment } from "react";
import { compose, withProps, lifecycle } from "recompose";
import { Default } from "../google-maps/themes";
import lojaEscolhida from "../google-maps/icons/store_active-32.png";
import loja from "../google-maps/icons/store-32.png";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Polyline
} from "react-google-maps";
import { GOOGLE_URL } from "../Endpoints";

class OrderDetailMap extends Component {
  state = {
    isCentered: false,
    directions: null
  };

  componentDidMount() {
    const { customer, store } = this.props;
    if (customer && store) {
      this.getDirections(customer, store);
    }
  }

  getDirections = (origin, destination) => {
    const DirectionsService = new window.google.maps.DirectionsService();

    DirectionsService.route(
      {
        origin: new window.google.maps.LatLng(
          origin.latitude,
          origin.longitude
        ),
        destination: new window.google.maps.LatLng(
          destination.latitude,
          destination.longitude
        ),
        travelMode: window.google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          const directions = result.routes[0].overview_path;
          const distance = result.routes[0].legs[0].distance.text;
          const duration = result.routes[0].legs[0].duration.text;
          this.setState({ directions, distance, duration });
        } else {
          console.error(`error fetching directions `, result);
        }
      }
    );
  };

  fitExampleBounds = () => {
    if (!this.state.isCentered) {
      this.setState({ isCentered: true });
      const { LatLng, LatLngBounds } = window.google.maps;
      const { customer, store, invitedStores } = this.props;
      const orderDetailBounds = [
        new LatLng(customer.latitude, customer.longitude),
        ...(store ? [new LatLng(store.latitude, store.longitude)] : []),
        ...(invitedStores
          ? invitedStores.map(s => new LatLng(s.latitude, s.longitude))
          : [])
      ];

      const bounds = new LatLngBounds();
      orderDetailBounds.forEach(bound => bounds.extend(bound));

      this.refs.map.fitBounds(bounds, 60);
      this.fitExampleBounds = () => { };
    }
  };

  render() {
    const { customer, store, invitedStores, radius } = this.props;
    const { directions, distance, duration } = this.state;
    //console.log(this.props)
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <GoogleMap
          ref="map"
          defaultZoom={4}
          defaultCenter={{ lat: 0, lng: 0 }}
          defaultOptions={{ styles: Default }}
          onTilesLoaded={this.fitExampleBounds}
        >
          {this.state.directions && (
            <Polyline
              path={this.state.directions}
              geodesic={true}
              options={{
                strokeColor: "#ff2343",
                strokeOpacity: 0.8,
                strokeWeight: 5,
                clickable: true
              }}
            />
          )}
          <Marker
            defaultIcon={lojaEscolhida}
            position={new google.maps.LatLng(this.props.store.latitude, this.props.store.longitude)}
          />
          <Marker
            position={new google.maps.LatLng(this.props.customer.latitude, this.props.customer.longitude)}
          />
        </GoogleMap>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5>Distância: {distance}</h5>
          <h5>Tempo estimado: {duration}</h5>
        </div>
      </div>
    );
  }
}

export default compose(
  withProps({
    googleMapURL: GOOGLE_URL,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `700px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      const DirectionsService = new google.maps.DirectionsService();
      DirectionsService.route({
        origin: new google.maps.LatLng(this.props.store.latitude, this.props.store.longitude),
        destination: new google.maps.LatLng(this.props.customer.latitude, this.props.customer.longitude),
        travelMode: google.maps.TravelMode.DRIVING,
      }, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      });
    }
  })
)(OrderDetailMap);
